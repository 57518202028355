import axios from 'axios';
import * as actionTypes from './actionTypes';


export const showSuccessSnackbar = data => {
    return dispatch => {
        dispatch({type: actionTypes.SNACKBAR_SUCCESS, payload: data});
    }
};

export const clearSnackbar = () => {
    return dispatch => {
        dispatch({type: actionTypes.SNACKBAR_CLEAR});
    }
};
