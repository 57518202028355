import axios from 'axios';
import * as actionTypes from './actionTypes';



export const getStatsChartLoading = () => {
    return {
        type: actionTypes.STATS_CHART_LOADING
    }
};

export const getStatsChartFail = (error) => {
    return {
        type: actionTypes.STATS_CHART_FAIL,
        error: error
    }
};

export const getStatsChartSuccess = (data) => {
    return {
        type: actionTypes.STATS_CHART_SUCCESS,
        payload: data
    }
};

export const getStatsChart = (token, login) => {
    return dispatch => {
        dispatch(getStatsChartLoading());
        axios.get('/admin/statschart/' + login + '?token=' + token )
            .then(res => {
                console.log(res);
                dispatch(getStatsChartSuccess(res.data));
            }).catch(error => {
            let message = "Server error, try again later";
            if (error.response){
                message = error.response.data.message;
            }
            dispatch(getStatsChartFail(message));
        });
    }
};