import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import { Button, Form, Alert } from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { Redirect } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner'
import * as actions from "../store/actions";
import * as actionTypes from "../store/actions/actionTypes";
import AdminClientTransactions from './AdminClientTransactions';
import AdminStatistics from './AdminStatistics';
import SuperResellerPassword from './SuperResellerPassword';
import Chart from './Chart';

function UpdateSuperReseller(props){

    const [superReseller, setSuperReseller] = useState(
        props.currentSuperReseller
    );
    const [transaction, setTransaction] = useState(0);

    const [credits, setCredits] = useState(1);

    const [description, setDescription] = useState();

    useEffect(() => {
        //props.onInitializeSaveClient();
        props.onInitializeSaveSuperReseller();
        props.onGetSuperReseller(props.token, props.currentSuperResellerName);

    }, []);

    useEffect(() => {
        setSuperReseller(props.currentSuperReseller);
        console.log('current super reseller object', superReseller);
    }, [props.currentSuperReseller]);


    useEffect(() => {
        if (props.isErrorSaveSuperReseller){
            var errorMessage = '' + props.isErrorSaveSuperReseller;
            props.onDisplaySnackbar({type: actionTypes.SNACKBAR_TYPE_ERROR, message: errorMessage});
        }
    }, [props.isErrorSaveSuperReseller]);

    const handleChange = (event) => {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        console.log('name', name);
        console.log('value', value);
        const key = target.key;
        setSuperReseller({
            ...superReseller,
            [name]: value
        })
    };

    const spinner = (
        <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
        />
    );

    let options = [...Array(100).keys()].slice(1);
    options = [...options, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1500, 2000, 2500, 5000, 10000];


    function handleSubmit(event) {
        event.preventDefault();

        const data = {
            name: superReseller.name,
            credits: credits,
            desc: superReseller.description,
            transaction: transaction

        };
        console.log('logging data', data);
        props.onSaveSuperReseller(props.token, data);

    }

    let saveSuperResellerRedirect = null;
    if(props.isSuccessSaveSuperReseller){
        //console.log('redirecting because', props.isSuccessSaveSuperReseller);
        var successMessage = 'Updated ' + props.isSuccessSaveSuperReseller.name;
        props.onDisplaySnackbar({type: actionTypes.SNACKBAR_TYPE_SUCCESS, message: successMessage});
        saveSuperResellerRedirect = <Redirect to="/superresellers" />
    }

    // let displayError = null;
    // if (props.isErrorSaveSuperReseller){
    //     //displayError = <Alert variant="danger">{props.isErrorSaveSuperReseller}</Alert>
    //     var errorMessage = '' + props.isErrorSaveSuperReseller;
    //     props.onDisplaySnackbar({type: actionTypes.SNACKBAR_TYPE_ERROR, message: errorMessage});
    // }

    return (
        <div>
            {saveSuperResellerRedirect}
            <Container>
                <Row>
                    <Col md={3}>
                        <Row>
                            <Col md={12} mb={5}>
                                <Card>
                                    <Card.Header as="h5">Update {props.currentSuperReseller.name}</Card.Header>
                                    <Card.Body>
                                        <h5>{props.currentSuperReseller.credits} Credits</h5>
                                        <Form onSubmit={handleSubmit}>
                                            <Form.Group controlId="transaction">
                                                <Form.Control
                                                    as="select"
                                                    onChange={e => setTransaction(e.target.value)}
                                                >
                                                    <option value={0} >Add</option>
                                                    <option value={1} >Remove</option>

                                                </Form.Control>
                                            </Form.Group>
                                            <Form.Group controlId="credits">
                                                <Form.Control
                                                    as="select"
                                                    onChange={e => setCredits(e.target.value)}
                                                >
                                                    {options.map(option => {
                                                        return <option value={option}>{option}</option>
                                                    })}

                                                </Form.Control>
                                            </Form.Group>
                                            <Form.Group controlId="description" >
                                                <Form.Control
                                                    value={superReseller.description}
                                                    name="description"
                                                    placeholder="Description"
                                                    onChange={handleChange}
                                                    type="text"
                                                />
                                            </Form.Group>
                                            <Button
                                                block
                                                disabled={props.isLoadingSaveSuperReseller}
                                                type="submit"
                                            >
                                                {props.isLoadingSaveSuperReseller
                                                    ? spinner
                                                    : 'Submit'}
                                            </Button>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={12} mb={5}>
                                <Card>
                                    <Card.Header as="h5">Change password</Card.Header>
                                    <Card.Body><SuperResellerPassword /></Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={9}>
                        <Row>
                            <Col md={12} mb={5}>
                                <Card>
                                    <Card.Header as="h5">Transactions</Card.Header>
                                    <Card.Body><AdminClientTransactions /></Card.Body>
                                </Card>
                            </Col>
                            <Col md={12} mb={5}>
                                <Card>
                                    <Card.Header as="h5">Statistics History</Card.Header>
                                    <Card.Body><AdminStatistics /></Card.Body>
                                </Card>
                            </Col>
                            <Col md={12} mb={5}>
                                <Card>
                                    <Card.Header as="h5">Statistics Chart</Card.Header>
                                    <Card.Body><Chart /></Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>

                </Row>
            </Container>

        </div>
    )

}

const mapDispatchToProps = dispatch => {
    return {
        onGetSuperReseller: (token, name) => dispatch(actions.getSuperReseller(token, name)),
        onInitializeSaveSuperReseller: () => dispatch(actions.initializeSaveSuperReseller()),
        onSaveSuperReseller: (token, data) => dispatch(actions.saveSuperReseller(token, data)),
        onDisplaySnackbar: (message) => dispatch(actions.showSuccessSnackbar(message))
    }
};

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        currentSuperResellerName: state.superReseller.name,
        currentSuperReseller: state.superReseller.item,
        isLoadingSuperReseller: state.superReseller.loading,
        isErrorSaveSuperReseller: state.saveSuperReseller.error,
        isSuccessSaveSuperReseller: state.saveSuperReseller.super_reseller,
        isLoadingSaveSuperReseller: state.saveSuperReseller.loading

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateSuperReseller);