import * as actionTypes from '../actions/actionTypes';

const initialState = {
    total: 0,
    reseller_list: [],
    loading: null,
    error: null
};

const reducer = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.RESELLERS_LOADING:
            return {
                ...state,
                loading: true,
                error: null,
                reseller_list: []
            };
        case actionTypes.RESELLERS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error,
                reseller_list: []
            };
        case actionTypes.RESELLERS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                total: action.payload.count,
                reseller_list: action.payload.data
            };
        default:
            return state;
    }
};

export default reducer;