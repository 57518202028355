import React, {useState, useEffect, useLayoutEffect} from 'react';
import { MDBDataTable } from 'mdbreact';
import * as actions from "../store/actions";
import {connect} from "react-redux";
import Spinner from 'react-bootstrap/Spinner';
import moment from 'moment';
import * as actionTypes from "../store/actions/actionTypes";

function DatatablePage(props) {

    const [myData, setMyData] = useState();

    useEffect(() => {
        //console.log('logging dashboard client list');
        //props.onInitializeGetClient();
        //props.onInitializeSaveClient();
        //props.onInitializeAddClient();
        //props.onInitializeDeleteClient();
        //props.onInitializeRetrieveCredits();
        //props.onGetClientList(props.token, 1, props.searchClients);

    }, []);



    let loading = (

        <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
        </Spinner>
    );

    let clientsData = [];

/*    if (props.isLoading){
        console.log('inside datatable loading', props.isLoading);
        console.log('datatable', props.clients);
        clientsData = props.clients.map((client) => {
            console.log('inside client map', client);
            return {
                login: 'ab',
                mac: 'ab',
                expiry: 'ab',
                renew: 'ab',
                status: 'ab',
                delete: 'cd'
            };
        });
    }*/
    let data2 = {};
    let loadedData = null;

    useEffect(() => {
        if (props.clients && props.clients.length){
            clientsData = props.clients.map((client) => {
                //console.log('inside client map', client);
                return {
                    login: client.name,
                    mac: client.mac,
                    expiry: moment(client.expiry).format('ddd, MMM Do YYYY'),
                    renew: 'ab',
                    status: client.status ? "Active" : "Inactive",
                    delete: 'cd'
                };
            });
            console.log('logging clients data inside useeffect', clientsData);
            data2 = {
                columns: [
                    {
                        label: 'Login',
                        field: 'login',
                        sort: 'asc',
                        width: 150
                    },
                    {
                        label: 'Mac',
                        field: 'mac',
                        sort: 'asc',
                        width: 150
                    },
                    {
                        label: 'Expiry',
                        field: 'expiry',
                        sort: 'asc',
                        width: 200
                    },
                    {
                        label: 'Renew',
                        field: 'renew',
                        sort: 'asc',
                        width: 100
                    },
                    {
                        label: 'Status',
                        field: 'status',
                        sort: 'asc',
                        width: 150
                    },
                    {
                        label: 'Delete',
                        field: 'delete',
                        sort: 'asc',
                        width: 100
                    }
                ],

                rows: clientsData
            };
            loadedData = <MDBDataTable
                            striped
                            bordered
                            small
                            hover
                            data={data2}
                        />;
            console.log('logging data2 insidue useEffect', data2);
            console.log('logging props clients length', props.clients.length);
            console.log('loaded data', loadedData);
            setMyData(loadedData);
        }

    },[props.clients]);


    console.log('props clients::',props.clients);
    console.log('clientsdata:', clientsData);
    console.log('data2', data2);

    return (
        <div>

            {props.isLoading
                ? loading
                : myData}

        </div>

    );
}

const mapDispatchToProps = dispatch => {
    return {
        onGetClientList: (token, page, search) => dispatch(actions.clientList(token, page, search)),

    }
};

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        clients: state.clients.client_list,
        isLoading: state.clients.loading,
        total_clients: state.clients.total,
        current_page: state.clients.current_page,
        per_page: state.clients.per_page,
        total_pages: state.clients.total_pages,
        isSetLoginName: state.clientReducer.name,
        isDeleteClient: state.deleteClientReducer,
        searchClients: state.searchClients.search
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DatatablePage);
