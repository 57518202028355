import React, { useState, useEffect } from "react";
import { Button, Form, Alert } from "react-bootstrap";
import {connect, useSelector} from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import moment from 'moment';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import DataTable from 'react-data-table-component';
import TextField from '@material-ui/core/TextField';

import * as actions from '../store/actions/index';
import * as actionTypes from '../store/actions/actionTypes';


function ClientTransactions(props) {

    const [dataRows, setDataRows] = useState();
    const [filterText, setFilterText] = useState('');
    const [filterData, setFilterData] = useState('');

    useEffect(()=>{
        props.onGetClientTransactions(props.token, props.currentClientName);
    },[]);

    const spinner = (
        <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
        />
    );

    const composeTransactionMessage = (t) => {
        let desc = '';
        if ('description' in t){
            desc = ':: ' + t.description;
        }
        let expiry = '';
        if ('expiry' in t){
            expiry = ', expiry ' + moment(t.expiry).format('MMM Do YYYY');
        }
        return <p><b>{moment(t.created).format('MMM Do YYYY hh:mm a')}</b>: {t.type} {t.credits} credit(s) {expiry} {desc}</p>
    };

    let transactionsData = [];

    useEffect(() => {
        if (props.transactions && props.transactions.length){
            transactionsData = props.transactions.map((t) => {
                //console.log('inside client map', client);
                return {
                    date: moment(t.created).format('YYYY-MM-DD hh:mm a'),
                    type: t.type,
                    description: t.description,
                    //mac: client.mac,
                    credits: t.credits,
                    expiry: moment(t.expiry).format('YYYY-MM-DD')
                };
            });
            setDataRows(transactionsData);
            setFilterData(transactionsData);
        }
    },[props.transactions]);

    useEffect(() => {
        //console.log('insided useEffect', filterText);
        if (dataRows && dataRows.length){
            //console.log('filter data', clientsData);
            let data = dataRows.filter(item => {
                if (item.description && item.description.toLowerCase().includes(filterText.toLowerCase())) {
                    return true;
                } else if (item.type && item.type.toLowerCase().includes(filterText.toLowerCase())) {
                    return true;
                } else {
                    return false;
                }
            });
            setFilterData(data);
        }

    }, [filterText]);

    const sortIcon = <ArrowDownward />;

    const dataColumns = [
        {
            name: 'Date',
            selector: 'date',
            sortable: true,
            width: '170px'
        },
        {
            name: 'Type',
            selector: 'type',
            sortable: true,
            width: 100
        },
        {
            name: 'Credits',
            selector: 'credits',
            sortable: true,
            width: 100
        },
        {
            name: 'Expiry',
            selector: 'expiry',
            sortable: true,
            width: 200
        },
        {
            name: 'Description',
            selector: 'description',
            sortable: true,
            width: 200
        }
    ];

    return (
        <div>
            <DataTable
                columns={dataColumns}
                data={filterData}
                pagination
                sortIcon={sortIcon}
                striped
                noHeader={true}
            />
        </div>

    );
}

const mapDispatchToProps = dispatch => {
    return {
        onGetClientTransactions: (token, login) => dispatch(actions.getTransactionsClient(token, login))

    }
};

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        currentClientName: state.clientReducer.name,
        transactions: state.transactions.transactions,
        isLoadingTransactions: state.transactions.loading,
        isFailTransactions: state.transactions.error
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientTransactions);