import React from "react";
import {Route, Switch, Redirect} from "react-router-dom";
import {connect} from "react-redux";
import Dashboard from "./containers/Dashboard";
import Clients from "./containers/Clients";
import AddClient from "./containers/AddClient";
import UpdateClient from "./containers/UpdateClient";
import Login from "./containers/Login";
import Logout from "./containers/Logout";
import NotFound from "./containers/NotFound";
import AdminDashboard from "./containers/AdminDashboard";
import Resellers from "./containers/Resellers";
import AddReseller from "./containers/AddReseller";
import UpdateReseller from "./containers/UpdateReseller";
import SuperResellers from "./containers/SuperResellers";
import AddSuperReseller from "./containers/AddSuperReseller";
import UpdateSuperReseller from "./containers/UpdateSuperReseller";
import AdminMessage from "./containers/AdminMessage";
import ResellerDashboard from "./containers/ResellerDashboard";
import SuperResellerDashboard from "./containers/SuperResellerDashboard";
import Dealers from "./containers/Dealers";
import AddDealer from "./containers/AddDealer";
import UpdateDealer from "./containers/UpdateDealer";
import DealerTransactions from './containers/DealerTransactions';
import ResellerTransactions from './containers/ResellerTransactions';
import SuperResellerTransactions from './containers/SuperResellerTransactions';
import AdminTransactions from './containers/AdminTransactions';
import AdminMac from './containers/AdminMac';

function Routes(props) {
    let routes = (
        <Switch>
            <Route path="/login" exact component={Login} />
            <Redirect to="/login" />
        </Switch>
    );

    if (props.isAuthenticated && props.role==='dealer'){
        routes = (
            <Switch>
                <Route path="/" exact component={Dashboard} />
                <Route path="/clients" exact component={Clients} />
                <Route path="/addclient" exact component={AddClient} />
                <Route path="/updateclient" exact component={UpdateClient} />
                <Route path="/transactions" exact component={DealerTransactions} />
                <Route path="/logout" component={Logout} />
                <Redirect to="/" />
                <Route component={NotFound} />
            </Switch>
        );
    }

    if (props.isAuthenticated && props.role==='reseller'){
        routes = (
            <Switch>
                <Route path="/" exact component={ResellerDashboard} />
                <Route path="/dealers" exact component={Dealers} />
                <Route path="/adddealer" exact component={AddDealer} />
                <Route path="/updatedealer" exact component={UpdateDealer} />
                <Route path="/transactions" exact component={ResellerTransactions} />
                <Route path="/logout" component={Logout} />
                <Redirect to="/" />
                <Route component={NotFound} />
            </Switch>
        );
    }

    if (props.isAuthenticated && props.role==='superreseller'){
        routes = (
            <Switch>
                <Route path="/" exact component={SuperResellerDashboard} />
                <Route path="/resellers" exact component={Resellers} />
                <Route path="/addreseller" exact component={AddReseller} />
                <Route path="/updatereseller" exact component={UpdateReseller} />
                <Route path="/transactions" exact component={SuperResellerTransactions} />
                <Route path="/logout" component={Logout} />
                <Redirect to="/" />
                <Route component={NotFound} />
            </Switch>
        );
    }

    if (props.isAuthenticated && props.role==='admin'){
        routes = (
            <Switch>
                <Route path="/" exact component={AdminDashboard} />
                <Route path="/superresellers" exact component={SuperResellers} />
                <Route path="/addsuperreseller" exact component={AddSuperReseller} />
                <Route path="/updatesuperreseller" exact component={UpdateSuperReseller} />
                <Route path="/message" exact component={AdminMessage} />
                <Route path="/deletemac" exact component={AdminMac} />
                <Route path="/transactions" exact component={AdminTransactions} />
                <Route path="/logout" component={Logout} />
                <Redirect to="/" />
                <Route component={NotFound} />
            </Switch>
        );
    }

    return (
        <>
            {routes}
        </>
    );
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
        role: state.auth.role
    }
};

export default connect(mapStateToProps, null)(Routes);