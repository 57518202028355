import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import { Button, Form, Alert } from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Redirect } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner'
import Paper from '@material-ui/core/Paper';
import Card from 'react-bootstrap/Card';
import moment from 'moment';
import MaskedInput from 'react-text-mask';

import { makeStyles } from '@material-ui/core/styles';
import * as actions from "../store/actions";
import * as actionTypes from "../store/actions/actionTypes";
import ClientTransactions from './ClientTransactions';
import RetrieveCredits from './RetrieveCredits';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap'

    },
}));

function UpdateClient(props){

    const [client, setClient] = useState(
        props.currentClient
    );
    const [months, setMonths] = useState("");
    const [packagesList, setPackagesList] = useState(
        props.packages
    );
    const [packageSelection, setPackageSelection] = useState("");
    const [newPassword, setNewPassword] = useState("");

    const classes = useStyles();

    useEffect(() => {
        props.onInitializeSaveClient();
        props.onInitializeRetrieveCredits();
        props.onInitializeResetClient();
        props.onGetClient(props.token, props.currentClientName);
        props.onGetSubPackagesList(props.token, props.currentClientName);
    }, []);

    useEffect(() => {
        setClient(props.currentClient);
        setPackageSelection(props.currentClient.package_selection);
    }, [props.currentClient]);

    useEffect(()=>{
        setPackagesList(props.packages);
    }, [props.packages]);

    useEffect(() => {
        if (props.isErrorSaveClient){
            var errorMessage = '' + props.isErrorSaveClient;
            props.onDisplaySnackbar({type: actionTypes.SNACKBAR_TYPE_ERROR, message: errorMessage});
        }
    }, [props.isErrorSaveClient]);

    useEffect(() => {
        if (props.isErrorResetClient){
            var errorMessage = '' + props.isErrorResetClient;
            props.onDisplaySnackbar({type: actionTypes.SNACKBAR_TYPE_ERROR, message: errorMessage});
        }
    }, [props.isErrorResetClient]);

    useEffect(() => {
    if (props.isSuccessResetClient){
        props.onGetClient(props.token, props.currentClientName);
        console.log('reset ', props.isSuccessResetClient);
        var successMessage = 'Reset ' + props.isSuccessResetClient.name;
        props.onDisplaySnackbar({type: actionTypes.SNACKBAR_TYPE_SUCCESS, message: successMessage});
        props.onInitializeResetClient();
    }
    },[props.isSuccessResetClient]);

    function validateForm() {
        if (client.ministra){
            return client.description.length < 50 && client.phone.length < 15 && client.password.length > 1;
        } else {
            return client.mac.length === 17 && client.description.length < 50 && client.phone.length < 15 && client.password.length > 1;
        }

    }

    const spinner = (
        <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
        />
    );

    const handleChange = (event) => {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const key = target.key;
        setClient({
            ...client,
            [name]: value
        })
    };

    const handleToggle = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const key = target.key;
        setPackagesList({
            ...packagesList,
            [name]: {
                ...packagesList[name],
                checked: value
            }
        })
    };

    const handleResetClient = () => {
        console.log('reseting...', client.name);
        props.onResetClient(props.token, client.name);
    };

    function handleSubmit(event) {
        event.preventDefault();
        //console.log("login", client.name);
        //console.log("password", client.password);
        //console.log("macaddress", client.mac);
        //console.log("months", months);
        //console.log("description", client.description);
        //console.log("phone", client.phone);
        const data = {
            login: client.name,
            password: client.password,
            o_mac: client.o_mac,
            stb_mac: client.mac,
            ministra: client.ministra,
            months: months,
            desc: client.description,
            phone: client.phone,
            status: client.status,
            package_selection: packageSelection,
            packages: packagesList,
            locked: client.locked
        };
        //console.log('logging data', data);
        props.onSaveClient(props.token, data);

    }

    let displayCustomPackages = null;
    if (packageSelection == 6){
        displayCustomPackages = (<div className="mb-3">

            {Object.keys(packagesList).map((key,value) => {
                return(<Form.Check
                    type="checkbox"
                    onChange={handleToggle}
                    key={key}
                    id={key}
                    name={key}
                    label={packagesList[key].name}
                    checked={packagesList[key].checked}
                />);
            })}
            {console.log('packages list: ', packagesList)}
            {console.log('package selection: ', packageSelection)}

        </div>)
    }

    let displayMacAddress = null;
    if (!client.ministra){
        displayMacAddress = (<Form.Group>
            <MaskedInput
                mask={['0', '0', ':' , '1', 'A', ':' , '7', '9', ':', /[A-Fa-f0-9]/, /[A-Fa-f0-9]/, ':' , /[A-Fa-f0-9]/, /[A-Fa-f0-9]/, ':' , /[A-Fa-f0-9]/, /[A-Fa-f0-9]/]}
                className="form-control"
                placeholder="Mac Address"
                value={client.mac}
                guide={true}
                id="mac"
                name="mac"
                onChange={handleChange}
            />
        </Form.Group>);
    }

    let saveClientRedirect = null;
    if(props.isSuccessSaveClient){
        console.log('redirecting because', props.isSuccessSaveClient);
        props.onGetCredits(props.token);
        var successMessage = 'Updated ' + props.isSuccessSaveClient.name;
        props.onDisplaySnackbar({type: actionTypes.SNACKBAR_TYPE_SUCCESS, message: successMessage});
        saveClientRedirect = <Redirect to="/clients" />
    }

    // let displayError = null;
    // if (props.isErrorSaveClient){
    //     displayError = <Alert variant="danger">{props.isErrorSaveClient}</Alert>
    // }
    /*
                            <option value={1} selected={(packageSelection===1)?true:false}>All Packages</option>
                            <option value={2} selected={(packageSelection===2)?true:false}>English/Sports/Adults</option>
                            <option value={3} selected={(packageSelection===3)?true:false}>South Asian/English/Sports/Adult</option>
                            <option value={4} selected={(packageSelection===4)?true:false}>South Asian/English/Sports/No Adult</option>
                            <option value={5} selected={(packageSelection===5)?true:false}>English/Portuguese/Brazil/Sports/Adult</option>
                            <option value={6} selected={(packageSelection===6)?true:false}>Custom</option>

                            <option value={1} selected={(packageSelection===1)?true:false}>All Packages</option>
                            <option value={4} selected={(packageSelection===4)?true:false}>English/Spanish/Portuguese/Brazil/Sports/No Adult</option>
                            <option value={5} selected={(packageSelection===5)?true:false}>English/Spanish/Portuguese/Brazil/Sports/Adult</option>
                            <option value={6} selected={(packageSelection===6)?true:false}>Custom</option>
     */

    return (
        <div>
            {saveClientRedirect}
            <Container fluid>
                <Row>
                    <Col md={3}>
                <Card>
                <Card.Header as="h5">Update {props.currentClient.name}</Card.Header>
                <Card.Body>
                <Form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <Form.Check
                            type="checkbox"
                            onChange={handleChange}
                            key="status"
                            id="status"
                            name="status"
                            label="Active"
                            checked={client.status}
                        />
                        <Form.Check
                            type="checkbox"
                            onChange={handleChange}
                            key="locked"
                            id="locked"
                            name="locked"
                            label="IP Lock"
                            checked={client.locked}
                        />
                    </div>
                    <Form.Group controlId="password" >
                        <Form.Control
                            value={client.password}
                            name="password"
                            placeholder="Password"
                            onChange={handleChange}
                            type="text"
                        />
                    </Form.Group>
                    <Form.Group controlId="description" >
                        <Form.Control
                            value={client.description}
                            name="description"
                            placeholder="Description"
                            onChange={handleChange}
                            type="text"
                        />
                    </Form.Group>
                    <Form.Group>
                        <MaskedInput
                            mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                            className="form-control"
                            placeholder="Enter a phone number"
                            value={client.phone}
                            guide={true}
                            id="phone"
                            name="phone"
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <div className="mb-3">
                        <Form.Check
                            type="checkbox"
                            onChange={handleChange}
                            key="ministra"
                            id="ministra"
                            name="ministra"
                            label="Ministra"
                            checked={client.ministra}
                        />
                    </div>
                    {displayMacAddress}
                    <Form.Group controlId="months">
                        <Form.Control
                            as="select"
                            onChange={e => setMonths(e.target.value)}
                        >
                            <option value={0}>Add months</option>
                            <option value={1}>1 Month</option>
                            <option value={2}>2 Months</option>
                            <option value={3}>3 Months</option>
                            <option value={4}>4 Months</option>
                            <option value={5}>5 Months</option>
                            <option value={6}>6 Months</option>
                            <option value={7}>7 Months</option>
                            <option value={8}>8 Months</option>
                            <option value={9}>9 Months</option>
                            <option value={10}>10 Months</option>
                            <option value={11}>11 Months</option>
                            <option value={12}>12 Months</option>
                        </Form.Control>
                    </Form.Group>
                    {console.log('logging client package selection', packageSelection)}
                    <Form.Group controlId="packageSelection">
                        <Form.Control
                            as="select"
                            onChange={e => setPackageSelection(e.target.value)}
                        >
                            <option value={1} selected={(packageSelection===1)?true:false}>All Packages</option>
                            <option value={7} selected={(packageSelection===7)?true:false}>All Packages/No Adult</option>
                            <option value={2} selected={(packageSelection===2)?true:false}>English/Sports/Adults</option>
                            <option value={3} selected={(packageSelection===3)?true:false}>South Asian/English/Sports/Adult</option>
                            <option value={4} selected={(packageSelection===4)?true:false}>South Asian/English/Sports/No Adult</option>
                            <option value={5} selected={(packageSelection===5)?true:false}>English/Portuguese/Brazil/Sports/Adult</option>
                            <option value={13} selected={(packageSelection===13)?true:false}>English/Sports/No Adults</option>
                            <option value={14} selected={(packageSelection===14)?true:false}>English/Sports/Portuguese/Brasil/Adults</option>
                            <option value={15} selected={(packageSelection===15)?true:false}>English/Sports/Portuguese/Brasil/No Adults</option>
                            <option value={16} selected={(packageSelection===16)?true:false}>English/Sports/Spanish/Adults</option>
                            <option value={17} selected={(packageSelection===17)?true:false}>English/Sports/Spanish/No Adults</option>
                            <option value={18} selected={(packageSelection===18)?true:false}>English/Sports/Portuguese/Brazilian/Italian/Spanish/South America/Latino/Adults</option>
                            <option value={19} selected={(packageSelection===19)?true:false}>English/Sports/Portuguese/Brazilian/Italian/Spanish/South America/Latino/No Adults</option>
                            <option value={20} selected={(packageSelection===20)?true:false}>English/Sports/Spanish/Portuguese/Italian/Adults</option>
                            <option value={21} selected={(packageSelection===21)?true:false}>English/Sports/Spanish/Portuguese/Italian/No Adults</option>
                            <option value={22} selected={(packageSelection===22)?true:false}>English/Sports/Caribbean/Adults</option>
                            <option value={23} selected={(packageSelection===23)?true:false}>English/Sports/Caribbean/No Adults</option>
                            <option value={24} selected={(packageSelection===24)?true:false}>English/Sports/Caribbean/South Asian/No Adults</option>
                            <option value={25} selected={(packageSelection===25)?true:false}>English/Sports/Caribbean/South Asian/Adults</option>
                            <option value={26} selected={(packageSelection===26)?true:false}>English/Sports/Ex Yugoslavia/Adults</option>
                            <option value={27} selected={(packageSelection===27)?true:false}>English/Sports/Ex Yugoslavia/No Adults</option>
                            <option value={28} selected={(packageSelection===28)?true:false}>English/Sports/Portuguese/Brasil/Italian/Spanish/Adults</option>
                            <option value={32} selected={(packageSelection===32)?true:false}>English/Sports/Portuguese/Brasil/Italian/Spanish/No Adults</option>
                            <option value={33} selected={(packageSelection===33)?true:false}>English/Sports/Italian/Adults</option>
                            <option value={34} selected={(packageSelection===34)?true:false}>English/Sports/Italian/No Adults</option>
                            <option value={35} selected={(packageSelection===35)?true:false}>English/Sports/Latino/Mexico/Spain/South America/Adults</option>
                            <option value={36} selected={(packageSelection===36)?true:false}>English/Sports/Latino/Mexico/Spain/South America/No Adults</option>
                            <option value={37} selected={(packageSelection===37)?true:false}>English/Sports/Arabic/No Adults</option>
                            <option value={38} selected={(packageSelection===38)?true:false}>English/Sports/Arabic/Adults</option>
                            <option value={39} selected={(packageSelection===39)?true:false}>English/Sports/Greek/Adults</option>
                            <option value={40} selected={(packageSelection===40)?true:false}>English/Sports/Greek/No Adults</option>
                            <option value={41} selected={(packageSelection===41)?true:false}>English/Sports/Polish/Romania/Ex Yugoslavia/Adults</option>
                            <option value={42} selected={(packageSelection===42)?true:false}>English/Sports/Polish/Romania/Ex Yugoslavia/No Adults</option>
                            <option value={43} selected={(packageSelection===43)?true:false}>English/Sports/French CA/African/French/No Adults</option>
                            <option value={44} selected={(packageSelection===44)?true:false}>English/Sports/French CA/African/French/Adults</option>
                            <option value={45} selected={(packageSelection===45)?true:false}>English/Sports/German/No Adults</option>
                            <option value={46} selected={(packageSelection===46)?true:false}>English/Sports/German/Adults</option>
                            <option value={47} selected={(packageSelection===47)?true:false}>English/Sports/Vietnamese/Korean/Chinese/Filipino/No Adults</option>
                            <option value={48} selected={(packageSelection===48)?true:false}>English/Sports/Vietnamese/Korean/Chinese/Filipino/Adults</option>
                            <option value={49} selected={(packageSelection===49)?true:false}>English/Sports/Hindi/Urdu/Punjabi/Adults</option>
                            <option value={50} selected={(packageSelection===50)?true:false}>English/Sports/Hindi/Urdu/Punjabi/No Adults</option>
                        </Form.Control>
                    </Form.Group>
                    {displayCustomPackages}
                    <Button
                        block
                        disabled={!validateForm() || props.isLoadingSaveClient}
                        type="submit"
                    >
                        {props.isLoadingSaveClient
                            ? spinner
                            : 'Submit'}
                    </Button>
                </Form>
                </Card.Body>
                </Card>
                    </Col>
                    <Col md={9}>
                        <Row>
                            <Col md={12} mb={5}>
                                <Card>
                                    <Card.Header as="h5">Information</Card.Header>
                                    <Card.Body>
                                        <p><b>Locked to IP: </b>{props.currentClient.locked_to_ip}</p>
                                        <p><b>Last updated </b>{moment(props.currentClient.last_updated).format('ddd, MMM Do YYYY hh:mm a')}</p>
                                        <p><b>Password </b>{props.currentClient.password}</p>
                                        <p><b>Expiry </b>{moment(props.currentClient.expiry).format('ddd, MMM Do YYYY')}</p>
                                        <p><b>Stb Serial </b>{props.currentClient.stb_sn}</p>
                                        <p><b>Stb Type </b>{props.currentClient.stb_type}</p>
                                        <p><b>Stb Version </b>{props.currentClient.version}</p>
                                        <p><b>Last seen </b>{moment(props.currentClient.last_active).format('ddd, MMM Do YYYY hh:mm a')}</p>
                                        <p><b>{props.currentClient.online}</b></p>
                                        <Button block variant="warning" onClick={handleResetClient}>CLICK HERE TO FIX ALL OF YOUR PROBLEMS</Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={12} mb={5}>
                                <Card>
                                    <Card.Header as="h5">Transactions</Card.Header>
                                    <Card.Body><ClientTransactions /></Card.Body>
                                </Card>
                            </Col>

                            <Col md={12} mb={5}>
                                <Card>
                                    <Card.Header as="h5">Retrieve credits</Card.Header>
                                    <Card.Body><RetrieveCredits /></Card.Body>
                                </Card>
                            </Col>
                        </Row>

                    </Col>

                </Row>

            </Container>


        </div>
    )

}

const mapDispatchToProps = dispatch => {
    return {

        onGetClient: (token, name) => dispatch(actions.getClient(token, name)),
        onGetSubPackagesList: (token, name) => dispatch(actions.subPackagesList(token, name)),
        onSaveClient: (token, data) => dispatch(actions.saveClient(token, data)),
        onResetClient: (token, name) => dispatch(actions.resetClient(token, name)),
        onInitializeSaveClient: () => dispatch(actions.initializeSaveClient()),
        onInitializeResetClient: () => dispatch(actions.initializeResetClient()),
        onInitializeRetrieveCredits: () => dispatch(actions.initializeRetrieveCredits()),
        onGetCredits: (token) => dispatch(actions.creditsUser(token)),
        onDisplaySnackbar: (message) => dispatch(actions.showSuccessSnackbar(message))
    }
};

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        packages: state.packages.package_list,
        isLoading: state.packages.loading,
        currentClientName: state.clientReducer.name,
        isLoadingSaveClient: state.saveClient.loading,
        currentClient: state.clientReducer.client,
        isErrorSaveClient: state.saveClient.error,
        isSuccessSaveClient: state.saveClient.client,
        isErrorResetClient: state.resetClient.error,
        isSuccessResetClient: state.resetClient.client
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateClient);