import React, { useState, useEffect } from "react";
import { Button, Form, Alert } from "react-bootstrap";
import {connect, useSelector} from 'react-redux';
import { Redirect } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import ReCAPTCHA from "react-google-recaptcha";
import * as actions from '../store/actions/index';
import * as actionTypes from '../store/actions/actionTypes';


import "./Login.css";

function Login(props) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [captcha, setCaptcha] = useState("");

    useEffect(() => {
        if (props.errorAuth){
            var errorMessage = '' + props.errorAuth;
            props.onDisplaySnackbar({type: actionTypes.SNACKBAR_TYPE_ERROR, message: errorMessage});
        }
    }, [props.errorAuth]);

    function validateForm() {
        return email.length > 0 && password.length > 0;
    }

    function onChange(value) {
        //console.log("Captcha value:", value)
        setCaptcha(value);
    }

    function handleSubmit(event) {
        event.preventDefault();
        console.log("email", email);
        console.log("password", password);
        //console.log("captcha", captcha);
        props.onAuth(email, password, captcha);
    }


    let authRedirect = null;

    if (props.isAuthenticated){

        authRedirect = <Redirect to="/" />
    }

    // let errorDisplay = null;
    // if (props.errorAuth){
    //     //errorDisplay = <Alert variant='danger'>{props.errorAuth}</Alert>
    //     props.onDisplaySnackbar({type: actionTypes.SNACKBAR_TYPE_ERROR, message: props.errorAuth});
    // }

    return (
        <div className="Login">
            {authRedirect}
            <Container>
            <Card>
                <Card.Body>
            <form onSubmit={handleSubmit}>
                <Form.Group controlId="email" >
                    <Form.Control
                        autoFocus
                        type="text"
                        value={email}
                        placeholder="Enter login name"
                        onChange={e => setEmail(e.target.value)}
                    />
                </Form.Group>
                <Form.Group controlId="password" >
                    <Form.Control
                        value={password}
                        placeholder="Enter password"
                        onChange={e => setPassword(e.target.value)}
                        type="password"
                    />
                </Form.Group>
                <ReCAPTCHA
                    sitekey="6LezG4sUAAAAANeC4nab_zDxP0GgUJEXkVn-DKfM"
                    onChange={onChange}
                />
                <Button
                    block
                    disabled={!validateForm()}
                    type="submit"
                >
                    Login
                </Button>
            </form>
                </Card.Body>
            </Card>
            </Container>
        </div>
    );
}

const mapDispatchToProps = dispatch  => {
    return {
        onAuth: (email, password, captcha) => dispatch(actions.auth(email, password, captcha)),
        onDisplaySnackbar: (message) => dispatch(actions.showSuccessSnackbar(message))
    }
};

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
        errorAuth: state.auth.error
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);