import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import { Button, Form, Alert } from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Redirect } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner'
import * as actions from "../store/actions";
import * as actionTypes from "../store/actions/actionTypes";


function AddSuperReseller(props){

    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");


    useEffect(() => {
        console.log('inside addsuperreseller');
        props.onInitializeAddSuperReseller();
    }, []);

    //const handleToggle = ({target}) =>
    //    setPackagesList(s => ({ ...s, [target.name]: !s[target.name]}));

    useEffect(() => {
        if (props.isErrorAddSuperReseller){
            var errorMessage = '' + props.isErrorAddSuperReseller;
            props.onDisplaySnackbar({type: actionTypes.SNACKBAR_TYPE_ERROR, message: errorMessage});
        }
    }, [props.isErrorAddSuperReseller]);

    const spinner = (
        <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
        />
    );

    function validateForm() {
        return login.length > 0 && password.length > 0;
    }

    function handleSubmit(event) {
        event.preventDefault();
        console.log("login", login);
        console.log("password", password);

        const data = {
            login: login,
            password: password,

        };
        props.onAddSuperReseller(props.token, data);
    }

    let addSuperResellerRedirect = null;
    if(props.isSuccessAddSuperReseller){
        var successMessage = 'Added ' + props.isSuccessAddSuperReseller.name;
        props.onDisplaySnackbar({type: actionTypes.SNACKBAR_TYPE_SUCCESS, message: successMessage});
        console.log('logging success adding', props.isSuccessAddSuperReseller.name);
        addSuperResellerRedirect = <Redirect to="/superresellers" />
    }

    // let displayError = null;
    // if (props.isErrorAddSuperReseller){
    //     //displayError = <Alert variant="danger">{props.isErrorAddSuperReseller}</Alert>
    //     var errorMessage = '' + props.isErrorAddSuperReseller;
    //     props.onDisplaySnackbar({type: actionTypes.SNACKBAR_TYPE_ERROR, message: errorMessage});
    // }


    return (
        <div>
            {addSuperResellerRedirect}
            <h3>Add Super Reseller</h3>
            <Container>

                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="login" >
                        <Form.Control
                            autoFocus
                            type="text"
                            value={login}
                            placeholder="Enter login name"
                            onChange={e => setLogin(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="password" >
                        <Form.Control
                            value={password}
                            placeholder="Enter password"
                            onChange={e => setPassword(e.target.value)}
                            type="text"
                        />
                    </Form.Group>
                    <Button
                        block
                        disabled={!validateForm() || props.isLoadingAddSuperReseller}
                        type="submit"
                    >
                        {props.isLoadingAddSuperReseller
                            ? spinner
                            : 'Submit'}
                    </Button>
                </Form>
            </Container>
        </div>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        onInitializeAddSuperReseller: () => dispatch(actions.initializeAddSuperReseller()),
        onAddSuperReseller: (token, data) => dispatch(actions.addSuperReseller(token, data)),
        onDisplaySnackbar: (message) => dispatch(actions.showSuccessSnackbar(message))
    }
};

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        isLoadingAddSuperReseller: state.addSuperReseller.loading,
        isErrorAddSuperReseller: state.addSuperReseller.error,
        isSuccessAddSuperReseller: state.addSuperReseller.super_reseller

    }
};

export default connect(mapStateToProps,mapDispatchToProps)(AddSuperReseller);