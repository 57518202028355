import * as actionTypes from '../actions/actionTypes';
/*
const initialState = {
    message: null,
    loading: null,
    error: null
};*/

const reducer = (state = {}, action) => {
    switch(action.type){
        case actionTypes.SNACKBAR_CLEAR:
            return {
                ...state,
                successSnackbarOpen: false,
                errorSnackbarOpen: false,
                infoSnackbarOpen: false
            };
        case actionTypes.SNACKBAR_SUCCESS:
            return {
                ...state,
                successSnackbarOpen: true,
                successSnackbarMessage: action.payload.message,
                snackbarType: action.payload.type,
                message: null
            };

        default:
            return state;
    }
};

export default reducer;