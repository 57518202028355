import * as actionTypes from '../actions/actionTypes';

const initialState = {
    on_date: [],
    clients: [],
    loading: null,
    error: null
};

const reducer = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.TOTAL_STATS_CHART_LOADING:
            return {
                ...state,
                loading: true,
                error: null,
                on_date: [],
                clients: []
            };
        case actionTypes.TOTAL_STATS_CHART_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error,
                on_date: [],
                clients: []
            };
        case actionTypes.TOTAL_STATS_CHART_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                on_date: action.payload.onDate,
                clients: action.payload.clients
            };
        default:
            return state;
    }
};

export default reducer;