import * as actionTypes from '../actions/actionTypes';

const initialState = {
    transactions: [],
    loading: null,
    error: null
};

const reducer = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.TRANSACTIONS_LOADING:
            return {
                ...state,
                loading: true,
                error: null,
                transactions: []
            };
        case actionTypes.TRANSACTIONS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error,
                transactions: []
            };
        case actionTypes.TRANSACTIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                transactions: action.payload
            };
        default:
            return state;
    }
};

export default reducer;