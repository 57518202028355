import * as actionTypes from '../actions/actionTypes';

const initialState = {
    client: {
        name: '',
        password: '',
        o_mac: '',
        mac: '',
        created: null,
        expiry: null,
        status: false,
        phone: '',
        description: ''
    },
    name: null,
    loading: null,
    error: null
};

const reducer = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.SET_CURRENT_CLIENT:
            return {
                ...state,
                loading: null,
                error: null,
                client: {...state, name: '',
                    password: '',
                    o_mac: '',
                    mac: '',
                    created: null,
                    expiry: null,
                    status: false,
                    phone: '',
                    description: ''},
                name: action.payload
            };
        case actionTypes.GET_CLIENT_INITIALIZE:
            return {
                ...state,
                loading: null,
                error: null,
                client: {...state, name: '',
                    password: '',
                    o_mac: '',
                    mac: '',
                    created: null,
                    expiry: null,
                    status: false,
                    phone: '',
                    description: ''},
                name: null
            };
        case actionTypes.GET_CLIENT_LOADING:
            return {
                ...state,
                loading: true,
                error: null,
                client: {...state, name: '',
                    password: '',
                    o_mac: '',
                    mac: '',
                    created: null,
                    expiry: null,
                    status: false,
                    phone: '',
                    description: ''}

            };
        case actionTypes.GET_CLIENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error,
                client: {...state, name: '',
                    password: '',
                    o_mac: '',
                    mac: '',
                    created: null,
                    expiry: null,
                    status: false,
                    phone: '',
                    description: ''}

            };
        case actionTypes.GET_CLIENT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                client: action.payload
            };
        default:
            return state;
    }
};

export default reducer;