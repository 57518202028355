import axios from 'axios';
import * as actionTypes from './actionTypes';



export const getTotalStatsChartLoading = () => {
    return {
        type: actionTypes.TOTAL_STATS_CHART_LOADING
    }
};

export const getTotalStatsChartFail = (error) => {
    return {
        type: actionTypes.TOTAL_STATS_CHART_FAIL,
        error: error
    }
};

export const getTotalStatsChartSuccess = (data) => {
    return {
        type: actionTypes.TOTAL_STATS_CHART_SUCCESS,
        payload: data
    }
};

export const getTotalStatsChart = (token) => {
    return dispatch => {
        dispatch(getTotalStatsChartLoading());
        axios.get('/admin/statstotals?token=' + token )
            .then(res => {
                console.log(res);
                dispatch(getTotalStatsChartSuccess(res.data));
            }).catch(error => {
            let message = "Server error, try again later";
            if (error.response){
                message = error.response.data.message;
            }
            dispatch(getTotalStatsChartFail(message));
        });
    }
};