import * as actionTypes from '../actions/actionTypes';

const initialState = {
    super_reseller: null,
    loading: null,
    error: null
};

const reducer = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.SAVE_SUPER_RESELLER_INITIALIZE:
            return {
                ...state,
                loading: null,
                error: null,
                super_reseller: null
            };
        case actionTypes.SAVE_SUPER_RESELLER_LOADING:
            return {
                ...state,
                loading: true,
                error: null,
                super_reseller: null
            };
        case actionTypes.SAVE_SUPER_RESELLER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error,
                super_reseller: null
            };
        case actionTypes.SAVE_SUPER_RESELLER_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                super_reseller: action.payload
            };
        default:
            return state;
    }
};

export default reducer;