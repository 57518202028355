import React, { useState, useEffect } from "react";
import { Button, Form, Alert } from "react-bootstrap";
import {connect, useSelector} from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import moment from 'moment';
import * as actions from '../store/actions/index';
import * as actionTypes from '../store/actions/actionTypes';


function RetrieveCredits(props) {

    const [credits, setCredits] = useState(0);

    useEffect(()=>{
        //props.onGetClientTransactions(props.token, props.currentClientName);
        props.onInitializeRetrieveCredits();
    },[]);

    useEffect(() => {
        if (props.isFailRetrieveCredits){
            console.log('logging isfailretrieve credits', props.isFailRetrieveCredits);
            var errorMessage = '' + props.isFailRetrieveCredits;
            props.onDisplaySnackbar({type: actionTypes.SNACKBAR_TYPE_ERROR, message: errorMessage});
        }
    }, [props.isFailRetrieveCredits]);

    const spinner = (
        <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
        />
    );

    function validateForm() {
        return credits != 0;
    }

    function handleSubmit(event) {
        event.preventDefault();
        //console.log("login", client.name);
        //console.log("password", client.password);
        //console.log("macaddress", client.mac);
        //console.log("months", months);
        //console.log("description", client.description);
        //console.log("phone", client.phone);
        const data = {
            login: props.currentClientName,
            credits: credits

        };
        console.log('logging data', data);
        props.onRetrieveCredits(props.token, data);

    }

    if(props.isSuccessRetrieveCredits){
        console.log('redirecting because', props.isSuccessRetrieveCredits);
        props.onGetCredits(props.token);
        props.onGetClient(props.token, props.currentClientName);
        props.onGetClientTransactions(props.token, props.currentClientName);
        props.onInitializeRetrieveCredits();
        var successMessage = 'Retrieved ' + credits + ' credit(s)';
        props.onDisplaySnackbar({type: actionTypes.SNACKBAR_TYPE_SUCCESS, message: successMessage});
    }

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="credits">
                    <Form.Control
                        as="select"
                        onChange={e => setCredits(e.target.value)}
                    >
                        <option value={0}>Select credits</option>
                        <option value={1}>1 Month</option>
                        <option value={2}>2 Months</option>
                        <option value={3}>3 Months</option>
                        <option value={4}>4 Months</option>
                        <option value={5}>5 Months</option>
                        <option value={6}>6 Months</option>
                        <option value={7}>7 Months</option>
                        <option value={8}>8 Months</option>
                        <option value={9}>9 Months</option>
                        <option value={10}>10 Months</option>
                        <option value={11}>11 Months</option>
                        <option value={12}>12 Months</option>
                    </Form.Control>
                </Form.Group>
                <Button
                    block
                    disabled={!validateForm() || props.isLoadingRetrieveCredits}
                    type="submit"
                >
                    {props.isLoadingSaveClient
                        ? spinner
                        : 'Submit'}
                </Button>

            </Form>
        </div>

    );
}

const mapDispatchToProps = dispatch => {
    return {
        onRetrieveCredits: (token, data) => dispatch(actions.retrieveCredits(token, data)),
        onInitializeRetrieveCredits: () => dispatch(actions.initializeRetrieveCredits()),
        onGetClient: (token, name) => dispatch(actions.getClient(token, name)),
        onGetCredits: (token) => dispatch(actions.creditsUser(token)),
        onGetClientTransactions: (token, login) => dispatch(actions.getTransactionsClient(token, login)),
        onDisplaySnackbar: (message) => dispatch(actions.showSuccessSnackbar(message))

    }
};

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        currentClientName: state.clientReducer.name,
        isSuccessRetrieveCredits: state.retrieveCredits.credits,
        isLoadingRetrieveCredits: state.retrieveCredits.loading,
        isFailRetrieveCredits: state.retrieveCredits.error
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(RetrieveCredits);