import axios from 'axios';
import * as actionTypes from './actionTypes';

export const getTransactionsLoading = () => {
    return {
        type: actionTypes.TRANSACTIONS_LOADING
    }
};

export const getTransactionsFail = (error) => {
    return {
        type: actionTypes.TRANSACTIONS_FAIL,
        error: error
    }
};

export const getTransactionsSuccess = (data) => {
    return {
        type: actionTypes.TRANSACTIONS_SUCCESS,
        payload: data
    }
};

export const getStatisticsLoading = () => {
    return {
        type: actionTypes.STATISTICS_LOADING
    }
};

export const getStatisticsFail = (error) => {
    return {
        type: actionTypes.STATISTICS_FAIL,
        error: error
    }
};

export const getStatisticsSuccess = (data) => {
    return {
        type: actionTypes.STATISTICS_SUCCESS,
        payload: data
    }
};

export const getTransactionsAdmin = (token) => {
    return dispatch => {
        dispatch(getTransactionsLoading());
        axios.get('/admin/transactions?token=' + token )
            .then(res => {
                console.log(res);
                dispatch(getTransactionsSuccess(res.data));
            }).catch(error => {
            let message = "Server error, try again later";
            if (error.response){
                message = error.response.data.message;
            }
            dispatch(getTransactionsFail(message));
        });
    }
};

export const getTransactionsAdminClient = (token, login) => {
    return dispatch => {
        dispatch(getTransactionsLoading());
        axios.get('/admin/history/' + login + '?token=' + token )
            .then(res => {
                console.log(res);
                dispatch(getTransactionsSuccess(res.data));
            }).catch(error => {
            let message = "Server error, try again later";
            if (error.response){
                message = error.response.data.message;
            }
            dispatch(getTransactionsFail(message));
        });
    }
};

export const getAdminStatistics = (token, login) => {
    return dispatch => {
        dispatch(getStatisticsLoading());
        axios.get('/admin/statistics/' + login + '?token=' + token )
            .then(res => {
                console.log(res);
                dispatch(getStatisticsSuccess(res.data));
            }).catch(error => {
            let message = "Server error, try again later";
            if (error.response){
                message = error.response.data.message;
            }
            dispatch(getStatisticsFail(message));
        });
    }
};


export const getTransactionsSuperReseller = (token) => {
    return dispatch => {
        dispatch(getTransactionsLoading());
        axios.get('/superreseller/transactions?token=' + token )
            .then(res => {
                console.log(res);
                dispatch(getTransactionsSuccess(res.data));
            }).catch(error => {
            let message = "Server error, try again later";
            if (error.response){
                message = error.response.data.message;
            }
            dispatch(getTransactionsFail(message));
        });
    }
};

export const getTransactionsSuperResellerClient = (token, login) => {
    return dispatch => {
        dispatch(getTransactionsLoading());
        axios.get('/superreseller/history/' + login + '?token=' + token )
            .then(res => {
                console.log(res);
                dispatch(getTransactionsSuccess(res.data));
            }).catch(error => {
            let message = "Server error, try again later";
            if (error.response){
                message = error.response.data.message;
            }
            dispatch(getTransactionsFail(message));
        });
    }
};

export const getTransactionsReseller = (token) => {
    return dispatch => {
        dispatch(getTransactionsLoading());
        axios.get('/reseller/transactions?token=' + token )
            .then(res => {
                console.log(res);
                dispatch(getTransactionsSuccess(res.data));
            }).catch(error => {
            let message = "Server error, try again later";
            if (error.response){
                message = error.response.data.message;
            }
            dispatch(getTransactionsFail(message));
        });
    }
};

export const getTransactionsResellerClient = (token, login) => {
    return dispatch => {
        dispatch(getTransactionsLoading());
        axios.get('/reseller/history/' + login + '?token=' + token )
            .then(res => {
                console.log(res);
                dispatch(getTransactionsSuccess(res.data));
            }).catch(error => {
            let message = "Server error, try again later";
            if (error.response){
                message = error.response.data.message;
            }
            dispatch(getTransactionsFail(message));
        });
    }
};


export const getTransactionsDealer = (token) => {
    return dispatch => {
        dispatch(getTransactionsLoading());
        axios.get('/api/transactions?token=' + token )
            .then(res => {
                console.log(res);
                dispatch(getTransactionsSuccess(res.data));
            }).catch(error => {
            let message = "Server error, try again later";
            if (error.response){
                message = error.response.data.message;
            }
            dispatch(getTransactionsFail(message));
        });
    }
};

export const getTransactionsClient = (token, login) => {
    return dispatch => {
        dispatch(getTransactionsLoading());
        axios.get('/api/history/' + login + '?token=' + token )
            .then(res => {
                console.log(res);
                dispatch(getTransactionsSuccess(res.data));
            }).catch(error => {
            let message = "Server error, try again later";
            if (error.response){
                message = error.response.data.message;
            }
            dispatch(getTransactionsFail(message));
        });
    }
};

