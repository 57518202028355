import axios from 'axios';
import * as actionTypes from './actionTypes';

export const creditsLoading = () => {
    return {
        type: actionTypes.CREDITS_LOADING
    }
};

export const creditsFail = (error) => {
    return {
        type: actionTypes.CREDITS_FAIL,
        error: error
    }
};

export const creditsSuccess = (data) => {
    return {
        type: actionTypes.CREDITS_SUCCESS,
        payload: data
    }
};

export const creditsUser = (token) => {
    return dispatch => {
        dispatch(creditsLoading());
        axios.get('/api/credits?token=' + token )
            .then(res => {
                console.log(res);
                dispatch(creditsSuccess(res.data));
            }).catch(error => {
            let message = "Server error, try again later";
            if (error.response){
                message = error.response.data.message;
            }
            dispatch(creditsFail(message));
        });
    }
};

export const creditsReseller = (token) => {
    return dispatch => {
        dispatch(creditsLoading());
        axios.get('/reseller/credits?token=' + token )
            .then(res => {
                console.log(res);
                dispatch(creditsSuccess(res.data));
            }).catch(error => {
            let message = "Server error, try again later";
            if (error.response){
                message = error.response.data.message;
            }
            dispatch(creditsFail(message));
        });
    }
};

export const creditsSuperReseller = (token) => {
    return dispatch => {
        dispatch(creditsLoading());
        axios.get('/superreseller/credits?token=' + token )
            .then(res => {
                console.log(res);
                dispatch(creditsSuccess(res.data));
            }).catch(error => {
            let message = "Server error, try again later";
            if (error.response){
                message = error.response.data.message;
            }
            dispatch(creditsFail(message));
        });
    }
};