import React, {useState, useEffect, useLayoutEffect} from "react";
import {connect} from 'react-redux';
import ProgressBar from 'react-bootstrap/ProgressBar'
import Table from 'react-bootstrap/Table'
//import Pagination from 'react-js-pagination';
import Pagination from 'react-bootstrap/Pagination';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import PageItem from 'react-bootstrap/PageItem'
import { Redirect } from 'react-router-dom';
import { NavLink, Link } from 'react-router-dom';
import {LinkContainer} from 'react-router-bootstrap';
import { Nav, NavItem} from "react-bootstrap";
import DeleteModal from './DeleteModal';
import moment from 'moment';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import DataTable from 'react-data-table-component';
import TextField from '@material-ui/core/TextField';
import * as actions from '../store/actions/index';

function Dealers(props) {

    const [dataRows, setDataRows] = useState();
    const [filterText, setFilterText] = useState('');
    const [filterData, setFilterData] = useState('');

    useEffect(() => {
        console.log('logging dashboard dealer list');
        props.onInitializeGetDealer();
        props.onInitializeAddDealer();
        props.onInitializeSaveDealer();
        props.onGetDealerList(props.token);

    }, []);

    const setCurrentDealer = (dealer) => {
        console.log('RESELLERS:: dealer name: ', dealer);
        props.onSetDealer(dealer);
        props.history.push('/updatedealer');
        //setClient(client);
    };

    let loading = (
        <Container>
            <Row>
                <Col></Col>
                <Col>
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </Col>
                <Col></Col>
            </Row>
        </Container>
    );

    let dealersData = [];

    useEffect(() => {
        if (props.dealers && props.dealers.length){
            dealersData = props.dealers.map((dealer) => {
                //console.log('inside client map', client);
                return {
                    login: <a href="#" onClick={() => setCurrentDealer(dealer.name)}>{dealer.name}</a>,
                    name: dealer.name,
                    description: dealer.description,
                    //mac: client.mac,
                    created: moment(dealer.created).format('YYYY-MM-DD'),
                    credits: dealer.credits
                };
            });
            setDataRows(dealersData);
            setFilterData(dealersData);
        }
    },[props.dealers]);

    useEffect(() => {
        //console.log('insided useEffect', filterText);
        if (dataRows && dataRows.length){
            //console.log('filter data', clientsData);
            let data = dataRows.filter(item => {
                if(item.name && item.name.toLowerCase().includes(filterText.toLowerCase())){
                    return true;
                } else if (item.description && item.description.toLowerCase().includes(filterText.toLowerCase())) {
                    return true;
                } else {
                    return false;
                }
            });
            setFilterData(data);
        }

    }, [filterText]);

    const sortIcon = <ArrowDownward />;

    const dataColumns = [
        {
            name: 'Login',
            selector: 'login',
            sortable: false,
            width: 150
        },
        {
            name: 'Description',
            selector: 'description',
            sortable: true,
            width: 150
        },
        {
            name: 'Created',
            selector: 'created',
            sortable: true,
            width: 200
        },
        {
            name: 'Credits',
            selector: 'credits',
            sortable: true,
            width: 200
        }
    ];


    return (
        <div>

            <div className="lander">
                <Container>
                    <Row>
                        <Col>
                            <h3>Dealers</h3>
                        </Col>
                        <Col></Col>
                        <Col>
                            <LinkContainer className="float-right" to="/adddealer"><Link to="/adddealer" className="btn btn-outline-success">Add Dealer</Link></LinkContainer>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <DataTable
                                columns={dataColumns}
                                data={filterData}
                                pagination
                                sortIcon={sortIcon}
                                striped
                                noHeader={true}
                                subHeader
                                subHeaderComponent={
                                    (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <TextField id="outlined-basic" label="Search" variant="outlined" size="small" style={{ margin: '5px' }} value={filterText} onChange={e => setFilterText(e.target.value)} />
                                        </div>
                                    )
                                }
                            />
                        </Col>
                    </Row>

                </Container>
            </div>
        </div>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        onGetDealerList: (token) => dispatch(actions.dealerList(token)),
        onInitializeGetDealer: () => dispatch(actions.initializeGetDealer()),
        onInitializeAddDealer: () => dispatch(actions.initializeAddDealer()),
        onInitializeSaveDealer: () => dispatch(actions.initializeSaveDealer()),
        onSetDealer: (name) => dispatch(actions.setDealer(name))
    }
};

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        total: state.dealers.total,
        isLoading: state.dealers.loading,
        dealers: state.dealers.dealer_list

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Dealers);