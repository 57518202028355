import * as actionTypes from '../actions/actionTypes';

const initialState = {
    current_page: 1,
    pages: 1,
    total: 0,
    per_page: 0,
    total_pages: 0,
    client_list: [],
    loading: null,
    error: null
};

const reducer = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.CLIENTS_LOADING:
            return {
                ...state,
                loading: true,
                error: null,
                client_list: []
            };
        case actionTypes.CLIENTS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error,
                client_list: []
            };
        case actionTypes.CLIENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                total: action.payload.count,
                current_page: action.payload.current_page,
                per_page: action.payload.per_page,
                total_pages: action.payload.total_pages,
                client_list: action.payload.data
            };
        default:
            return state;
    }
};

export default reducer;