import axios from 'axios';
import * as actionTypes from './actionTypes';

export const renewCreditsLoading = () => {
    return {
        type: actionTypes.RENEW_CREDITS_LOADING
    }
};

export const renewCreditsFail = (error) => {
    return {
        type: actionTypes.RENEW_CREDITS_FAIL,
        error: error
    }
};

export const renewCreditsSuccess = (data) => {
    return {
        type: actionTypes.RENEW_CREDITS_SUCCESS,
        payload: data
    }
};

export const renewCreditsInitialize = () => {
    return {
        type: actionTypes.RENEW_CREDITS_INITIALIZE
    }
};

export const initializeRenewCredits = () => {
    return dispatch => {
        dispatch(renewCreditsInitialize())
    }
};

export const renewCredits = (token, data) => {
    return dispatch => {
        dispatch(renewCreditsLoading());
        axios.post('/api/renew', {...data, token: token})
            .then(res => {
                dispatch(renewCreditsSuccess(res.data))
            }).catch(error => {
            //console.log('logging error: ', error.response.data);
            let message = "Server error, try again later";
            if (error.response){
                message = error.response.data.message;
            }

            dispatch(renewCreditsFail(message));
        })
    }
};

