import React, {useState, useEffect, useLayoutEffect} from "react";
import {connect} from 'react-redux';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import DatatablePage from './DatatablePage';
import * as actions from '../store/actions/index';

function Dashboard(props) {

    useEffect(() => {
        props.onGetClientList(props.token);
        props.onGetCredits(props.token);
        props.onGetMessage(props.token);
    }, []);

    return (
        <div>
            <div className="lander">
                <h3>Dashboard</h3>
                <Container>
                    <Row>
                        <Col>
                            <Alert variant="primary">{props.message}</Alert>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Alert variant="secondary"><b>Ministra</b> player is now supported! Create or Update account and select <b>Ministra</b> checkbox. The Ministra player can be downloaded on Android, iOS, AppleTV, Roku, Tizen, WebOS and Samsung Smart TV!</Alert>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Alert variant="info">Click <a href="http://"><b>here</b></a> for support.</Alert>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div><h3>{props.credits}</h3></div>
                            <div><h4>Credits</h4></div>
                        </Col>
                        <Col>
                            <div><h3>{props.total_clients}</h3></div>
                            <div><h4>Total Clients</h4></div>
                        </Col>
                    </Row>
                </Container>
             </div>
        </div>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        onGetClientList: (token) => dispatch(actions.clientList(token)),
        onGetCredits: (token) => dispatch(actions.creditsUser(token)),
        onGetMessage: (token) => dispatch(actions.getMessageDealer(token))
    }
};

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        clients: state.clients.client_list,
        total_clients: state.clients.total,
        credits: state.credits.credits,
        message: state.getMessage.message
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);