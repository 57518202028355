import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import { Button, Form, Alert } from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Redirect } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import PhoneInput from 'react-phone-number-input/input';
import MaskedInput from 'react-text-mask';
import * as actions from "../store/actions";
import * as actionTypes from "../store/actions/actionTypes";


function AddClientQuick(props){

    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const [macAddress, setMacAddress] = useState("00:1A:79:");
    const [months, setMonths] = useState("");
    const [description, setDescription] = useState("");
    const [phone, setPhone] = useState("");
    const [packageSelection, setPackageSelection] = useState("");
    const [packagesList, setPackagesList] = useState(
        props.packages
    );

    useEffect(() => {
        console.log('inside addclient');
        props.onInitializeAddClient();
        props.onGetPackageList(props.token);
    }, []);

    useEffect(()=>{
        setPackagesList(props.packages);
    }, [props.packages]);

    useEffect(() => {
        if (props.isErrorAddClient){
            var errorMessage = '' + props.isErrorAddClient;
            props.onDisplaySnackbar({type: actionTypes.SNACKBAR_TYPE_ERROR, message: errorMessage});
        } else {
            props.onInitializeAddClient();
        }
    }, [props.isErrorAddClient]);

    useEffect(() => {
        //props.onGetClientList(props.token, props.current_page);
    },[props.isSuccessAddClient]);


    //const handleToggle = ({target}) =>
    //    setPackagesList(s => ({ ...s, [target.name]: !s[target.name]}));

    const spinner = (
        <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
        />
    );

    const handleToggle = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const key = target.key;
        setPackagesList({
            ...packagesList,
            [name]: {
                ...packagesList[name],
                checked: value
            }
        })
    };

    function validateForm() {
        return macAddress.length === 17 && login.length > 0 && login.length < 15;
    }

    function handleSubmit(event) {
        event.preventDefault();
        console.log("login", login);
        console.log("password", password);
        console.log("macaddress", macAddress);
        console.log("months", months);
        console.log("description", description);
        console.log("phone", phone);

        const data = {
            login: login,
            password: 'pass1334',
            stb_mac: macAddress,
            months: months,
            desc: description,
            phone: phone,
            package_selection: packageSelection,
            packages: packagesList
        };
        props.onAddClient(props.token, data);
    }

    let addClientRedirect = null;
    if(props.isSuccessAddClient){
        props.onGetCredits(props.token);
        props.onGetClientList(props.token, props.current_page);
        var successMessage = 'Added ' + props.isSuccessAddClient.name;
        props.onDisplaySnackbar({type: actionTypes.SNACKBAR_TYPE_SUCCESS, message: successMessage});
        props.onInitializeAddClient();

        //addClientRedirect = <Redirect to="/clients" />
    }
    /*
                        <option value={1}>All Packages</option>
                        <option value={2}>English/Sports/Adults</option>
                        <option value={3}>South Asian/English/Sports/Adult</option>
                        <option value={4}>South Asian/English/Sports/No Adult</option>
                        <option value={5}>English/Portuguese/Brazil/Sports/Adult</option>



                        <option value={1}>All Packages</option>
                        <option value={4}>English/Spanish/Portuguese/Brazil/Sports/No Adult</option>
                        <option value={5}>English/Spanish/Portuguese/Brazil/Sports/Adult</option>

     */

    // let displayError = null;
    // if (props.isErrorAddClient){
    //     displayError = <Alert variant="danger">{props.isErrorAddClient}</Alert>
    // }


    return (
              <Form onSubmit={handleSubmit}>
                  {console.log(macAddress.length)}
                    <Form.Row>
                        <Col>
                        <Form.Control
                            controlId="login"
                            autoFocus
                            type="text"
                            value={login}
                            placeholder="login"
                            onChange={e => setLogin(e.target.value)}
                        />
                        </Col>

                        <Form.Group>
                            <MaskedInput
                                mask={['0', '0', ':' , '1', 'A', ':' , '7', '9', ':', /[A-Fa-f0-9]/, /[A-Fa-f0-9]/, ':' , /[A-Fa-f0-9]/, /[A-Fa-f0-9]/, ':' , /[A-Fa-f0-9]/, /[A-Fa-f0-9]/]}
                                className="form-control"
                                placeholder="Mac Address"
                                value={macAddress}
                                guide={true}
                                id="macAddress"
                                onChange={e => setMacAddress(e.target.value)}
                            />
                        </Form.Group>
                        <Col md={1}>
                            <Form.Control
                                controlId="months"
                                as="select"
                                onChange={e => setMonths(e.target.value)}
                            >
                                <option value={0}>Trial - Expires at midnight</option>
                                <option value={"2d"}>Trial 2 days</option>
                                <option value={1}>1 Month</option>
                                <option value={2}>2 Months</option>
                                <option value={3}>3 Months</option>
                                <option value={4}>4 Months</option>
                                <option value={5}>5 Months</option>
                                <option value={6}>6 Months</option>
                                <option value={7}>7 Months</option>
                                <option value={8}>8 Months</option>
                                <option value={9}>9 Months</option>
                                <option value={10}>10 Months</option>
                                <option value={11}>11 Months</option>
                                <option value={12}>12 Months</option>
                            </Form.Control>
                        </Col>
                        <Col>
                            <Form.Group controlId="packageSelection">
                                <Form.Control
                                    as="select"
                                    onChange={e => setPackageSelection(e.target.value)}
                                >
                                    <option value={1}>All Packages</option>
                                    <option value={7}>All Packages/No Adult</option>
                                    <option value={2}>English/Sports/Adults</option>
                                    <option value={3}>South Asian/English/Sports/Adult</option>
                                    <option value={4}>South Asian/English/Sports/No Adult</option>
                                    <option value={5}>English/Portuguese/Brazil/Sports/Adult</option>

                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={1}>
                            <Button
                                block
                                disabled={!validateForm() || props.isLoadingAddClient}
                                type="submit"
                            >
                                {props.isLoadingAddClient
                                    ? spinner
                                    : 'Submit'}
                            </Button>
                        </Col>
                    </Form.Row>

                </Form>

    );
}

const mapDispatchToProps = dispatch => {
    return {
        onGetClientList: (token, page) => dispatch(actions.clientList(token, page)),
        onGetPackageList: (token) => dispatch(actions.packagesList(token)),
        onInitializeAddClient: () => dispatch(actions.initializeAddClient()),
        onAddClient: (token, data) => dispatch(actions.addClient(token, data)),
        onGetCredits: (token) => dispatch(actions.creditsUser(token)),
        onDisplaySnackbar: (message) => dispatch(actions.showSuccessSnackbar(message))
    }
};

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        packages: state.packages.package_list,
        isLoading: state.packages.loading,
        isLoadingAddClient: state.addClient.loading,
        isErrorAddClient: state.addClient.error,
        isSuccessAddClient: state.addClient.client,
        current_page: state.clients.current_page
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(AddClientQuick);