import React, {useState, useEffect, useLayoutEffect} from "react";
import {connect} from 'react-redux';
import ProgressBar from 'react-bootstrap/ProgressBar'
import Table from 'react-bootstrap/Table'
//import Pagination from 'react-js-pagination';
import Pagination from 'react-bootstrap/Pagination';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import PageItem from 'react-bootstrap/PageItem'
import { Redirect } from 'react-router-dom';
import { NavLink, Link } from 'react-router-dom';
import {LinkContainer} from 'react-router-bootstrap';
import { Nav, NavItem} from "react-bootstrap";
import moment from 'moment';
import DeleteModal from './DeleteModal';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import DataTable from 'react-data-table-component';
import TextField from '@material-ui/core/TextField';

import * as actions from '../store/actions/index';

function Resellers(props) {

    const [dataRows, setDataRows] = useState();
    const [filterText, setFilterText] = useState('');
    const [filterData, setFilterData] = useState('');

    useEffect(() => {
        console.log('logging dashboard reseller list');
        props.onInitializeGetReseller();
        props.onInitializeAddReseller();
        props.onInitializeSaveReseller();
        props.onGetResellerList(props.token);

    }, []);

    const setCurrentReseller = (reseller) => {
        console.log('RESELLERS:: reseller name: ', reseller);
        props.onSetReseller(reseller);
        props.history.push('/updatereseller');
        //setClient(client);
    };

    let loading = (
        <Container>
            <Row>
                <Col></Col>
                <Col>
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </Col>
                <Col></Col>
            </Row>
        </Container>
    );

    let resellersData = [];

    useEffect(() => {
        if (props.resellers && props.resellers.length){
            resellersData = props.resellers.map((reseller) => {
                //console.log('inside client map', client);
                return {
                    login: <a href="#" onClick={() => setCurrentReseller(reseller.name)}>{reseller.name}</a>,
                    name: reseller.name,
                    description: reseller.description,
                    //mac: client.mac,
                    created: moment(reseller.created).format('YYYY-MM-DD'),
                    credits: reseller.credits
                };
            });
            setDataRows(resellersData);
            setFilterData(resellersData);
        }
    },[props.resellers]);

    useEffect(() => {
        //console.log('insided useEffect', filterText);
        if (dataRows && dataRows.length){
            //console.log('filter data', clientsData);
            let data = dataRows.filter(item => {
                if(item.name && item.name.toLowerCase().includes(filterText.toLowerCase())){
                    return true;
                } else if (item.description && item.description.toLowerCase().includes(filterText.toLowerCase())) {
                    return true;
                } else {
                    return false;
                }
            });
            setFilterData(data);
        }

    }, [filterText]);

    const sortIcon = <ArrowDownward />;

    const dataColumns = [
        {
            name: 'Login',
            selector: 'login',
            sortable: false,
            width: 150
        },
        {
            name: 'Description',
            selector: 'description',
            sortable: true,
            width: 150
        },
        {
            name: 'Created',
            selector: 'created',
            sortable: true,
            width: 200
        },
        {
            name: 'Credits',
            selector: 'credits',
            sortable: true,
            width: 200
        }
    ];


    return (
        <div>

            <div className="lander">
                <Container>
                    <Row>
                        <Col>
                            <h3>Resellers</h3>
                        </Col>
                        <Col></Col>
                        <Col>
                            <LinkContainer className="float-right" to="/addreseller"><Link to="/addreseller" className="btn btn-outline-success">Add Reseller</Link></LinkContainer>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <DataTable
                                columns={dataColumns}
                                data={filterData}
                                pagination
                                sortIcon={sortIcon}
                                striped
                                noHeader={true}
                                subHeader
                                subHeaderComponent={
                                    (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <TextField id="outlined-basic" label="Search" variant="outlined" size="small" style={{ margin: '5px' }} value={filterText} onChange={e => setFilterText(e.target.value)} />
                                        </div>
                                    )
                                }
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        onGetResellerList: (token) => dispatch(actions.resellerList(token)),
        onInitializeGetReseller: () => dispatch(actions.initializeGetReseller()),
        onInitializeAddReseller: () => dispatch(actions.initializeAddReseller()),
        onInitializeSaveReseller: () => dispatch(actions.initializeSaveReseller()),
        onSetReseller: (name) => dispatch(actions.setReseller(name))
    }
};

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        total: state.resellers.total,
        isLoading: state.resellers.loading,
        resellers: state.resellers.reseller_list

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Resellers);