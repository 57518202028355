import React, { useState, useEffect } from "react";
import { Button, Form, Alert } from "react-bootstrap";
import {connect, useSelector} from 'react-redux';
import Badge from 'react-bootstrap/Badge';
import Spinner from 'react-bootstrap/Spinner';

import * as actions from '../store/actions/index';
import * as actionTypes from '../store/actions/actionTypes';


function CreditsBadge(props) {

    let variant="success";
    if(props.credits === 0){
        variant="danger";
    } else if((props.credits > 0) && (props.credits <= 20)){
        variant="warning";
    }

    const spinner = (
        <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
        />
    );

    const message = props.credits + ' Credits';

    return (
            <div>
                <Badge pill variant={variant}>{(props.isLoadingCredits)?spinner:message}</Badge>
            </div>

    );
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
        credits: state.credits.credits,
        isLoadingCredits: state.credits.loading
    }
};

export default connect(mapStateToProps, null)(CreditsBadge);