import React from 'react';
import {connect} from 'react-redux';
import { NavLink, Link } from 'react-router-dom';
import { Nav, NavItem} from "react-bootstrap";
import {LinkContainer} from 'react-router-bootstrap';
import CreditsBadge from './CreditsBadge';

function CreditsNav (props) {

    let navigationLinks = null;
    if (props.isAuthenticated && props.role==='dealer'){
        navigationLinks = (

            <Nav className="mr-auto">
                <CreditsBadge/>
            </Nav>
        )
    }

    if (props.isAuthenticated && props.role==='reseller'){
        navigationLinks = (
            <Nav className="mr-auto">
                <CreditsBadge/>
            </Nav>
        )
    }

    if (props.isAuthenticated && props.role==='superreseller'){
        navigationLinks = (
            <Nav className="mr-auto">
                <CreditsBadge/>
            </Nav>
        )
    }


    if (props.isAuthenticated && props.role==='admin'){
        navigationLinks = (
            <Nav className="mr-auto">

            </Nav>
        )
    }

    return (
        <>
            {navigationLinks}
        </>
    );
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
        role: state.auth.role
    }
};

export default connect(mapStateToProps, null)(CreditsNav);
