import React, { useState, useEffect } from "react";
import { Button, Form, Alert } from "react-bootstrap";
import {connect, useSelector} from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import moment from 'moment';
import * as actions from '../store/actions/index';
import * as actionTypes from '../store/actions/actionTypes';


function DealerPassword(props) {

    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    //useEffect(()=>{
        //props.onGetClientTransactions(props.token, props.currentClientName);
        //props.onInitializeRetrieveCredits();
    //},[]);

    useEffect(() => {
        if (props.isFailChangePassword){
            console.log('logging isfail', props.isFailChangePassword);
            var errorMessage = '' + props.isFailChangePassword;
            props.onDisplaySnackbar({type: actionTypes.SNACKBAR_TYPE_ERROR, message: errorMessage});
        }
    }, [props.isFailChangePassword]);

    const spinner = (
        <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
        />
    );

    function validateForm() {
        return password === password2 && password.length>3 && password2.length>3;
    }

    function handleSubmit(event) {
        event.preventDefault();
        //console.log("login", client.name);
        //console.log("password", client.password);
        //console.log("macaddress", client.mac);
        //console.log("months", months);
        //console.log("description", client.description);
        //console.log("phone", client.phone);
        const data = {
            name: props.currentDealerName,
            password: password

        };
        console.log('logging data', data);
        //props.onRetrieveCredits(props.token, data);
        props.onChangePassword(props.token, data);

    }

    if(props.isSuccessChangePassword){
        console.log('redirecting because', props.isSuccessChangePassword);
        //props.onGetCredits(props.token);
        //props.onGetClient(props.token, props.currentClientName);
        //props.onGetClientTransactions(props.token, props.currentClientName);
        props.onInitializeChangePassword();
        var successMessage = 'Changed password for ' + props.currentDealerName;
        props.onDisplaySnackbar({type: actionTypes.SNACKBAR_TYPE_SUCCESS, message: successMessage});
    }

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="password" >
                    <Form.Control
                        value={password}
                        placeholder="Enter password"
                        onChange={e => setPassword(e.target.value)}
                        type="text"
                    />
                </Form.Group>
                <Form.Group controlId="password" >
                    <Form.Control
                        value={password2}
                        placeholder="Re-enter password"
                        onChange={e => setPassword2(e.target.value)}
                        type="text"
                    />
                </Form.Group>
                <Button
                    block
                    disabled={!validateForm() || props.isLoadingChangePassword}
                    type="submit"
                >
                    {props.isLoadingChangePassword
                        ? spinner
                        : 'Submit'}
                </Button>

            </Form>
        </div>

    );
}

const mapDispatchToProps = dispatch => {
    return {
        onChangePassword: (token, data) => dispatch(actions.changePasswordDealer(token, data)),
        onInitializeChangePassword: () => dispatch(actions.initializeChangePassword()),
        //onGetClient: (token, name) => dispatch(actions.getClient(token, name)),
        //onGetCredits: (token) => dispatch(actions.creditsUser(token)),
        //onGetClientTransactions: (token, login) => dispatch(actions.getTransactionsClient(token, login)),
        onDisplaySnackbar: (message) => dispatch(actions.showSuccessSnackbar(message))

    }
};

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        currentDealerName: state.dealer.name,
        isSuccessChangePassword: state.passwordReducer.name,
        isLoadingChangePassword: state.passwordReducer.loading,
        isFailChangePassword: state.passwordReducer.error
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DealerPassword);