import React, { useState, useEffect } from "react";
import {Line} from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, PointElement, LinearScale, Title, CategoryScale, Tooltip } from 'chart.js';
import * as actions from "../store/actions";
import {connect} from "react-redux";
import moment from "moment/moment";

ChartJS.register(LineElement, PointElement, LinearScale, CategoryScale, Tooltip, Title);

function Chart(props){

    const [labels, setLabels] = useState([]);
    const [myData, setMyData] = useState([]);

    useEffect(()=>{
        props.onGetStatsChart(props.token, props.currentSuperResellerName);
    },[]);

    useEffect(() => {
        if (props.clients && props.clients.length){
            //transactionsData = props.transactions.map((t) => {
                //console.log('inside client map', client);
            //    return {
            //        date: moment(t.created).format('YYYY-MM-DD hh:mm a'),
            //        type: t.type,
            //        //mac: client.mac,
            //        login: t.login,
            //        credits: t.credits
            //    };
            //});
            setLabels(props.labels);
            setMyData(props.clients);
        }
    },[props.clients]);

    const data = {
        labels: labels,
        datasets: [
            {
                label: 'Dataset of Clients',
                fill: false,
                lineTension: 0.1,
                backgroundColor: 'rgba(75,192,192,0.4)',
                borderColor: 'rgba(75,192,192,1)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(75,192,192,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: myData
            }
        ]
    };

    return (
        <div>
            <Line data={data} />
        </div>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        onGetStatsChart: (token, name) => dispatch(actions.getStatsChart(token, name))
    }
};

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        currentSuperResellerName: state.superReseller.name,
        labels: state.statschart.on_date,
        clients: state.statschart.clients
        //currentSuperReseller: state.superReseller.item,
        //isLoadingSuperReseller: state.superReseller.loading,
        //isErrorSaveSuperReseller: state.saveSuperReseller.error,
        //isSuccessSaveSuperReseller: state.saveSuperReseller.super_reseller,
        //isLoadingSaveSuperReseller: state.saveSuperReseller.loading

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Chart);
