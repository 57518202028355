import axios from 'axios';
import * as actionTypes from './actionTypes';
import {
    addClientFail,
    addClientInitialize, addClientLoading, addClientSuccess, getClientFail, getClientInitialize, getClientLoading,
    getClientSuccess, saveClientFail, saveClientInitialize, saveClientLoading, saveClientSuccess,
    setCurrentClient
} from "./clients";

export const resellersLoading = () => {
    return {
        type: actionTypes.RESELLERS_LOADING
    }
};

export const getResellerLoading = () => {
    return {
        type: actionTypes.GET_RESELLER_LOADING
    }
};

export const saveResellerLoading = () => {
    return {
        type: actionTypes.SAVE_RESELLER_LOADING
    }
};

export const addResellerLoading = () => {
    return {
        type: actionTypes.ADD_RESELLER_LOADING
    }
};

export const resellersFail = (error) => {
    return {
        type: actionTypes.RESELLERS_FAIL,
        error: error
    }
};

export const getResellerFail = (error) => {
    return {
        type: actionTypes.GET_RESELLER_FAIL,
        error: error
    }
};

export const saveResellerFail = (error) => {
    return {
        type: actionTypes.SAVE_RESELLER_FAIL,
        error: error
    }
};

export const addResellerFail = (error) => {
    return {
        type: actionTypes.ADD_RESELLER_FAIL,
        error: error
    }
};

export const resellersSuccess = (data) => {
    return {
        type: actionTypes.RESELLERS_SUCCESS,
        payload: data
    }
};

export const setCurrentReseller = (login) => {
    return {
        type: actionTypes.SET_CURRENT_RESELLER,
        payload: login
    }
};

export const getResellerInitialize = () => {
    return {
        type: actionTypes.GET_RESELLER_INITIALIZE
    }
};

export const addResellerInitialize = () => {
    return {
        type: actionTypes.ADD_RESELLER_INITIALIZE
    }
};

export const saveResellerInitialize = () => {
    return {
        type: actionTypes.SAVE_RESELLER_INITIALIZE
    }
};

export const getResellerSuccess = (data) => {
    return {
        type: actionTypes.GET_RESELLER_SUCCESS,
        payload: data
    }
};

export const addResellerSuccess = (data) => {
    return {
        type: actionTypes.ADD_RESELLER_SUCCESS,
        payload: data
    }
};

export const saveResellerSuccess = (data) => {
    return {
        type: actionTypes.SAVE_RESELLER_SUCCESS,
        payload: data
    }
};


export const initializeGetReseller = () => {
    return dispatch => {
        dispatch(getResellerInitialize());
    }
};

export const initializeAddReseller = () => {
    return dispatch => {
        dispatch(addResellerInitialize());
    }
};

export const initializeSaveReseller = () => {
    return dispatch => {
        dispatch(saveResellerInitialize());
    }
};

export const setReseller = (login) => {
    return dispatch => {
        console.log('super reseller ', login);
        dispatch(setCurrentReseller(login));
    }
};

export const resellerList = (token) => {
    return dispatch => {
        dispatch(resellersLoading());
        axios.get('/superreseller/list?token=' + token)
            .then(res => {
                console.log(res);
                dispatch(resellersSuccess(res.data));
            }).catch(error => {
            let message = "Server error, try again later";
            if (error.response){
                message = error.response.data.message;
            }
            dispatch(resellersFail(message));
        });
    }
};

export const getReseller = (token, name) => {
    return dispatch => {
        dispatch(getResellerLoading());
        axios.get('/superreseller/reseller/' + name + '?token=' + token )
            .then(res => {
                dispatch(getResellerSuccess(res.data))
            }).catch(error => {
            //console.log('logging error getreseller: ', error.response.data);
            let message = "Server error, try again later";
            if (error.response){
                message = error.response.data.message;
            }

            dispatch(getResellerFail(message));
        })
    }
};

export const addReseller = (token, data) => {
    return dispatch => {
        dispatch(addResellerLoading());
        axios.post('/superreseller/reseller', {...data, token: token})
            .then(res => {
                dispatch(addResellerSuccess(res.data))
            }).catch(error => {
            //console.log('logging error: ', error.response.data);
            let message = "Server error, try again later";
            if (error.response){
                message = error.response.data.message;
            }
            dispatch(addResellerFail(message));
        })
    }
};

export const saveReseller = (token, data) => {
    return dispatch => {
        dispatch(saveResellerLoading());
        axios.post('/superreseller/save', {...data, token: token})
            .then(res => {
                dispatch(saveResellerSuccess(res.data))
            }).catch(error => {
            //console.log('logging error: ', error.response.data);
            let message = "Server error, try again later";
            if (error.response){
                message = error.response.data.message;
            }
            dispatch(saveResellerFail(message));
        })
    }
};
