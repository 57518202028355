import axios from 'axios';
import * as actionTypes from './actionTypes';

export const clientsLoading = () => {
    return {
        type: actionTypes.CLIENTS_LOADING
    }
};



export const clientsFail = (error) => {
    return {
        type: actionTypes.CLIENTS_FAIL,
        error: error
    }
};

export const clientsSuccess = (data) => {
    return {
        type: actionTypes.CLIENTS_SUCCESS,
        payload: data
    }
};

export const addClientInitialize = () => {
    return {
        type: actionTypes.ADD_CLIENT_INITIALIZE
    }
};

export const saveClientInitialize = () => {
    return {
        type: actionTypes.SAVE_CLIENT_INITIALIZE
    }
};

export const deleteClientInitialize = () => {
    return {
        type: actionTypes.DELETE_CLIENT_INITIALIZE
    }
};

export const resetClientInitialize = () => {
    return {
        type: actionTypes.RESET_CLIENT_INITIALIZE
    }
};

export const getClientInitialize = () => {
    return {
        type: actionTypes.GET_CLIENT_INITIALIZE
    }
};


export const addClientLoading = () => {
    return {
        type: actionTypes.ADD_CLIENT_LOADING
    }
};

export const resetClientLoading = () => {
    return {
        type: actionTypes.RESET_CLIENT_LOADING
    }
};

export const saveClientLoading = () => {
    return {
        type: actionTypes.SAVE_CLIENT_LOADING
    }
};

export const deleteClientLoading = () => {
    return {
        type: actionTypes.DELETE_CLIENT_LOADING
    }
};

export const getClientLoading = () => {
    return {
        type: actionTypes.GET_CLIENT_LOADING
    }
};


export const addClientFail = (error) => {
    return {
        type: actionTypes.ADD_CLIENT_FAIL,
        error: error
    }
};

export const saveClientFail = (error) => {
    return {
        type: actionTypes.SAVE_CLIENT_FAIL,
        error: error
    }
};

export const deleteClientFail = (error) => {
    return {
        type: actionTypes.DELETE_CLIENT_FAIL,
        error: error
    }
};

export const resetClientFail = (error) => {
    return {
        type: actionTypes.RESET_CLIENT_FAIL,
        error: error
    }
};

export const setCurrentClient = (login) => {
    return {
        type: actionTypes.SET_CURRENT_CLIENT,
        payload: login
    }
};

export const getClientFail = (error) => {
    return {
        type: actionTypes.GET_CLIENT_FAIL,
        error: error
    }
};

export const addClientSuccess = (data) => {
    return {
        type: actionTypes.ADD_CLIENT_SUCCESS,
        payload: data
    }
};

export const saveClientSuccess = (data) => {
    return {
        type: actionTypes.SAVE_CLIENT_SUCCESS,
        payload: data
    }
};

export const deleteClientSuccess = (data) => {
    return {
        type: actionTypes.DELETE_CLIENT_SUCCESS,
        payload: data
    }
};

export const resetClientSuccess = (data) => {
    return {
        type: actionTypes.RESET_CLIENT_SUCCESS,
        payload: data
    }
};

export const getClientSuccess = (data) => {
    return {
        type: actionTypes.GET_CLIENT_SUCCESS,
        payload: data
    }
};

export const saveSearch = (search) => {
    return {
        type: actionTypes.SEARCH_SAVE,
        payload: search
    }
};

export const resetSearch = () => {
    return {
        type: actionTypes.SEARCH_RESET
    }
};

export const searchClients = (search) => {
  return dispatch => {
      if (search == null || search=== ''){
          dispatch(resetSearch());
      } else {
          dispatch(saveSearch(search));
      }
  }
};

export const clientList = (token, page, search = '') => {
    return dispatch => {

        dispatch(clientsLoading());
        axios.get('/api/clients?token=' + token + '&page=' + page + '&search=' + search)
            .then(res => {
                console.log(res);
                dispatch(clientsSuccess(res.data));
            }).catch(error => {
                let message = "Server error, try again later";
                if (error.response){
                    message = error.response.data.message;
                }
                dispatch(clientsFail(message));
        });
    }
};

export const initializeAddClient = () => {
    return dispatch => {
        dispatch(addClientInitialize());
    }
};

export const initializeSaveClient = () => {
    return dispatch => {
        dispatch(saveClientInitialize());
    }
};

export const initializeDeleteClient = () => {
    return dispatch => {
        dispatch(deleteClientInitialize());
    }
};

export const initializeResetClient = () => {
    return dispatch => {
        dispatch(resetClientInitialize());
    }
};

export const initializeGetClient = () => {
    return dispatch => {
        dispatch(getClientInitialize());
    }
};

export const setClient = (login) => {
    return dispatch => {
        dispatch(setCurrentClient(login));
    }
};

export const getClient = (token, name) => {
    return dispatch => {
        dispatch(getClientLoading());
        axios.get('/api/client/' + name + '?token=' + token )
            .then(res => {
                dispatch(getClientSuccess(res.data))
            }).catch(error => {
            //console.log('logging error getclient: ', error.response.data);
            let message = "Server error, try again later";
            if (error.response){
                message = error.response.data.message;
            }

            dispatch(getClientFail(message));
        })
    }
};

export const addClient = (token, data) => {
    return dispatch => {
        dispatch(addClientLoading());
        axios.post('/api/client', {...data, token: token})
            .then(res => {
                dispatch(addClientSuccess(res.data))
            }).catch(error => {
                //console.log('logging error: ', error.response.data);
                let message = "Server error, try again later";
                if (error.response){
                    message = error.response.data.message;
                }

                dispatch(addClientFail(message));
        })
    }
};

export const saveClient = (token, data) => {
    return dispatch => {
        dispatch(saveClientLoading());
        axios.post('/api/saveclient', {...data, token: token})
            .then(res => {
                dispatch(saveClientSuccess(res.data))
            }).catch(error => {
            //console.log('logging error: ', error.response.data);
            let message = "Server error, try again later";
            if (error.response){
                message = error.response.data.message;
            }

            dispatch(saveClientFail(message));
        })
    }
};

export const deleteClient = (token, name) => {
    return dispatch => {
        dispatch(deleteClientLoading());
        axios.post('/api/remove', {token: token, name: name})
            .then(res => {
                dispatch(deleteClientSuccess(res.data))
            }).catch(error => {
            //console.log('logging error: ', error.response.data);
            let message = "Server error, try again later";
            if (error.response){
                message = error.response.data.message;
            }
            dispatch(deleteClientFail(message));
        })
    }
};

export const resetClient = (token, name) => {
    return dispatch => {
        dispatch(resetClientLoading());
        axios.post('/api/resetclient', {token: token, name: name})
            .then(res => {
                dispatch(resetClientSuccess(res.data))
            }).catch(error => {
            //console.log('logging error: ', error.response.data);
            let message = "Server error, try again later";
            if (error.response){
                if (error.response.status === 429){
                    message = error.response.data;
                } else {
                    message = error.response.data.message;
                }
            }
            dispatch(resetClientFail(message));
        })
    }
};
