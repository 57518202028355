import React, {useState, useEffect, useLayoutEffect} from "react";
import {connect} from 'react-redux';
import ProgressBar from 'react-bootstrap/ProgressBar'
import Table from 'react-bootstrap/Table'
import moment from 'moment';
//import Pagination from 'react-js-pagination';
import Pagination from 'react-bootstrap/Pagination';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import PageItem from 'react-bootstrap/PageItem'
import { Redirect } from 'react-router-dom';
import { NavLink, Link } from 'react-router-dom';
import {LinkContainer} from 'react-router-bootstrap';
import { Nav, NavItem} from "react-bootstrap";
import DeleteModal from './DeleteModal';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import DataTable from 'react-data-table-component';
import TextField from '@material-ui/core/TextField';
import AdminTotalChart from './AdminTotalChart';
import * as actions from '../store/actions/index';

function SuperResellers(props) {

    const [dataRows, setDataRows] = useState();
    const [filterText, setFilterText] = useState('');
    const [filterData, setFilterData] = useState('');
    const [totalClients, setTotalClients] = useState('');

    useEffect(() => {
        console.log('logging dashboard super reseller list');
        props.onInitializeGetSuperReseller();
        props.onInitializeAddSuperReseller();
        props.onInitializeSaveSuperReseller();
        props.onGetSuperResellerList(props.token);

    }, []);

    const setCurrentSuperReseller = (sreseller) => {
        console.log('RESELLERS:: reseller name: ', sreseller);
        props.onSetSuperReseller(sreseller);
        props.history.push('/updatesuperreseller');
        //setClient(client);
    };

    let loading = (
        <Container>
            <Row>
                <Col></Col>
                <Col>
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </Col>
                <Col></Col>
            </Row>
        </Container>
    );

    let superResellersData = [];

    useEffect(() => {
        if (props.superResellers && props.superResellers.length){
            superResellersData = props.superResellers.map((sreseller) => {
                //console.log('inside client map', client);
                return {
                    login: <a href="#" onClick={() => setCurrentSuperReseller(sreseller.name)}>{sreseller.name}</a>,
                    name: sreseller.name,
                    description: sreseller.description,
                    //mac: client.mac,
                    created: moment(sreseller.created).format('YYYY-MM-DD'),
                    credits: sreseller.credits,
                    resellers: sreseller.resellers,
                    dealers: sreseller.dealers,
                    clients: sreseller.clients
                };
            });
            setDataRows(superResellersData);
            setFilterData(superResellersData);

        }
    },[props.superResellers]);

    useEffect(() => {
        //console.log('insided useEffect', filterText);
        if (dataRows && dataRows.length){
            //console.log('filter data', clientsData);
            let data = dataRows.filter(item => {
                if(item.name && item.name.toLowerCase().includes(filterText.toLowerCase())){
                    return true;
                } else if (item.description && item.description.toLowerCase().includes(filterText.toLowerCase())) {
                    return true;
                } else {
                    return false;
                }
            });
            setFilterData(data);
        }

    }, [filterText]);

    const sortIcon = <ArrowDownward />;

    const dataColumns = [
        {
            name: 'Login',
            selector: 'login',
            sortable: false,
            width: 150
        },
        {
            name: 'Created',
            selector: 'created',
            sortable: true,
            width: 200
        },
        {
            name: 'Credits',
            selector: 'credits',
            sortable: true,
            width: 200
        },
        {
            name: 'Resellers',
            selector: 'resellers',
            sortable: true,
            width: 200
        },
        {
            name: 'Dealers',
            selector: 'dealers',
            sortable: true,
            width: 200
        },
        {
            name: 'Clients',
            selector: 'clients',
            sortable: true,
            width: 200
        }
    ];


    return (
        <div>

            <div className="lander">

                <Container>
                    <Row>
                        <Col md={8}>
                            <h3>Super Resellers</h3>
                            <h6>Total {props.totalClients} clients as of {moment(props.onDate).format('MMMM Do YYYY, h:mm a')}</h6>
                        </Col>
                        <Col></Col>
                        <Col md={3}>
                            <LinkContainer className="float-right" to="/addsuperreseller"><Link to="/addsuperreseller" className="btn btn-outline-success">Add Super Reseller</Link></LinkContainer>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <DataTable
                                columns={dataColumns}
                                data={filterData}
                                pagination
                                sortIcon={sortIcon}
                                striped
                                noHeader={true}
                                subHeader
                                subHeaderComponent={
                                    (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <TextField id="outlined-basic" label="Search" variant="outlined" size="small" style={{ margin: '5px' }} value={filterText} onChange={e => setFilterText(e.target.value)} />
                                        </div>
                                    )
                                }
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <AdminTotalChart />
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        onGetSuperResellerList: (token) => dispatch(actions.superResellerList(token)),
        onInitializeGetSuperReseller: () => dispatch(actions.initializeGetSuperReseller()),
        onInitializeAddSuperReseller: () => dispatch(actions.initializeAddSuperReseller()),
        onInitializeSaveSuperReseller: () => dispatch(actions.initializeSaveSuperReseller()),
        onSetSuperReseller: (name) => dispatch(actions.setSuperReseller(name))
    }
};

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        total: state.superResellers.total,
        totalClients: state.superResellers.total_clients,
        onDate: state.superResellers.date_of_total_clients,
        isLoading: state.superResellers.loading,
        superResellers: state.superResellers.super_reseller_list

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SuperResellers);