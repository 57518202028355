import React, {useState, useEffect, useLayoutEffect} from "react";
import {connect} from 'react-redux';
import ProgressBar from 'react-bootstrap/ProgressBar'
import Table from 'react-bootstrap/Table';
//import Pagination from 'react-js-pagination';
import Pagination from 'react-bootstrap/Pagination';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import PageItem from 'react-bootstrap/PageItem'
import { Redirect } from 'react-router-dom';
import { NavLink, Link } from 'react-router-dom';
import {LinkContainer} from 'react-router-bootstrap';
import { Nav, NavItem} from "react-bootstrap";
import DeleteModal from './DeleteModal';
import * as actions from '../store/actions/index';
import * as actionTypes from "../store/actions/actionTypes";
import AddClientQuick from './AddClientQuick';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import { green } from '@material-ui/core/colors';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';

import moment from 'moment';
import { MDBDataTable } from 'mdbreact';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import DataTable from 'react-data-table-component';
import TextField from '@material-ui/core/TextField';
import { Form, Alert } from "react-bootstrap";
import QuickRenew from './QuickRenew';
import DatatablePage from './DatatablePage';
import ReactTable from './ReactTable';


function Clients(props) {

    const [currentSelectedPage, setCurrentSelectedPage] = useState(props.current_page);
    const [modalShow, setModalShow] = useState(false);
    const [myData, setMyData] = useState();
    const [deleteClient, setDeleteClient] = useState();
    const [months, setMonths] = useState("");
    //const [myData, setMyData] = useState();
    const [dataRows, setDataRows] = useState();
    const [filterText, setFilterText] = useState('');
    const [filterData, setFilterData] = useState('');

    const [expanded, setExpanded] = React.useState(false);


    useEffect(() => {
        console.log('logging dashboard client list');
        props.onInitializeGetClient();
        props.onInitializeSaveClient();
        props.onInitializeAddClient();
        props.onInitializeDeleteClient();
        props.onInitializeRetrieveCredits();
        props.onGetClientList(props.token, currentSelectedPage, props.searchClients);

    }, [currentSelectedPage]);

    useEffect(() => {
       //console.log('select page: ', currentSelectedPage);
    }, [currentSelectedPage]);

    useEffect(() => {
        if (props.isDeleteClient.client){
            props.onGetClientList(props.token, currentSelectedPage);
            var successMessage = 'Deleted ' + props.isDeleteClient.client.name;
            props.onDisplaySnackbar({type: actionTypes.SNACKBAR_TYPE_SUCCESS, message: successMessage});

            setModalShow(false);
        }
    },[props.isDeleteClient.client]);

    let clientsData = [];
    let data2 = {};
    let loadedData = null;
    useEffect(() => {
        if (props.clients && props.clients.length){
            clientsData = props.clients.map((client) => {
                //console.log('inside client map', client);
                return {
                    login: <a href="#" onClick={() => setCurrentClient(client)}>{client.name}</a>,
                    name: client.name,
                    description: client.description,
                    mac: client.ministra ? "" : client.mac,
                    ministra: client.ministra ? "Yes" : "",
                    expiry: moment(client.expiry).format('YYYY-MM-DD'),
                    renew: <QuickRenew name={client.name}/>,
                    status: client.status ? "Active" : "Inactive",
                    delete: <Button variant="outline-danger" onClick={()=> setModalClient(client.name)}>Delete</Button>
                };
            });
            //console.log('logging clients data inside useeffect', clientsData);
            data2 = {
                columns: [
                    {
                        label: 'Login',
                        field: 'login',
                        sort: 'asc',
                        width: 150
                    },
                    {
                        label: 'Mac',
                        field: 'mac',
                        sort: 'asc',
                        width: 150
                    },
                    {
                        label: 'Ministra',
                        field: 'ministra',
                        sort: 'asc',
                        width: 50
                    },
                    {
                        label: 'Expiry',
                        field: 'expiry',
                        sort: 'asc',
                        width: 100
                    },
                    {
                        label: 'Renew',
                        field: 'renew',
                        sort: 'asc',
                        width: '56px'
                    },
                    {
                        label: 'Status',
                        field: 'status',
                        sort: 'asc',
                        width: '56px'
                    },
                    {
                        label: 'Delete',
                        field: 'delete',
                        sort: 'asc',
                        width: '56px'
                    }
                ],

                rows: clientsData
            };
            loadedData = <MDBDataTable
                striped
                bordered
                tfoot={false}
                fixed
                noBottomColumns
                headerColor="elegant-color"
                arrows
                small
                hover
                data={data2}
            />;
            //console.log('logging data2 insidue useEffect', data2);
            //console.log('logging props clients length', props.clients.length);
            //console.log('loaded data', loadedData);
            setMyData(data2);
            setDataRows(clientsData);
            setFilterData(clientsData);
        }

    },[props.clients]);

    useEffect(() => {
        //console.log('insided useEffect', filterText);
        if (dataRows && dataRows.length){
            //console.log('filter data', clientsData);
            let data = dataRows.filter(item => {
                if(item.name && item.name.toLowerCase().includes(filterText.toLowerCase())){
                    return true;
                } else if (item.mac && item.mac.toLowerCase().includes(filterText.toLowerCase())) {
                    return true;
                } else if (item.status && item.status.toLowerCase().includes(filterText.toLowerCase())) {
                    return true;
                } else if (item.description && item.description.toLowerCase().includes(filterText.toLowerCase())) {
                    return true;
                } else {
                    return false;
                }
            });
            setFilterData(data);
        }

    }, [filterText]);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handlePageChange = (e) => {
        //console.log('active page ', e.target.text);
        const page = e.target.text;
        if(page){
            if(page == 'First'){
                setCurrentSelectedPage(1);
            } else if(page == 'Last'){
                setCurrentSelectedPage(props.total_pages);
            } else {
                setCurrentSelectedPage(page);
            }
        }
    };


    const setCurrentClient = (client) => {
        console.log(client);
        props.onSetClient(client.name);
        props.history.push('/updateclient');
        //setClient(client);
    };

    const handleClose = () => setModalShow(false);

    const setModalClient = (client) => {
        setDeleteClient(client);
        console.log('logging delete client', deleteClient);
        setModalShow(true);
    };

    const handleDeleteClient = () => {
        console.log('deleting...', deleteClient);
        props.onDeleteClient(props.token, deleteClient);
    };

    const handleToggle = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const id = target.id;

        console.log('logging name', id);
        console.log('logging value', value);

    };

    let items = [];
    //let pageNumbers = <></>;


    for (let number = 1; number <= props.total_pages; number++){
        items.push(
            <Pagination.Item key={number} active={number === props.current_page} >
                {number}
            </Pagination.Item>
        );
    }


    let pageNumbers = (
        <Container>
            <Row mx-lg-n5>
                <Col></Col>
                <Col>
                    <Pagination onClick={handlePageChange}>
                        <Pagination.Item disabled={(props.current_page==1)?true:false}>First</Pagination.Item>
                        {items}
                        <Pagination.Item disabled={(props.current_page==props.total_pages)?true:false}>Last</Pagination.Item>
                    </Pagination>
                </Col>
                <Col></Col>
            </Row>
        </Container>

    );

    let loading = (
        <Container>
            <Row>
                <Col></Col>
                <Col>
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </Col>
                <Col></Col>
            </Row>
        </Container>
    );

    const sortIcon = <ArrowDownward />;

    const dataColumns = [
        {
            name: 'Login',
            selector: 'login',
            sortable: false,
            width: 150
        },
        {
            name: 'Description',
            selector: 'description',
            sortable: true,
            width: 150
        },
        {
            name: 'Mac',
            selector: 'mac',
            sortable: true,
            width: '150px'
        },
        {
            name: 'Ministra',
            selector: 'ministra',
            sortable: true,
            width: '100px'
        },
        {
            name: 'Expiry',
            selector: 'expiry',
            sortable: true,
            width: 200
        },
        {
            name: 'Status',
            selector: 'status',
            sortable: true,
            width: '80px'
        },
        {
            name: 'Renew',
            selector: 'renew',
            sortable: false,
            width: '107px'
        },
        {
            name: 'Delete',
            selector: 'delete',
            sortable: false,
            width: '105px'
        }
    ];


    return (
        <div>

            <div className="lander">
                <Container fluid>
                    <Row>
                        <Col>
                            <h3>Clients</h3>
                        </Col>
                        <Col></Col>
                        <Col className="text-right">
                            <AddCircleRoundedIcon
                                fontSize="large"
                                style={{ color: 'green' }}
                                onClick={handleExpandClick}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card>

                            <Collapse in={expanded} timeout="auto" unmountOnExit>
                                <CardContent>
                                    <AddClientQuick />
                                </CardContent>
                            </Collapse>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <DataTable
                                columns={dataColumns}
                                data={filterData}
                                pagination
                                sortIcon={sortIcon}
                                striped
                                noHeader={true}
                                subHeader
                                subHeaderComponent={
                                    (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <TextField id="outlined-basic" label="Search" variant="outlined" size="small" style={{ margin: '5px' }} value={filterText} onChange={e => setFilterText(e.target.value)} />
                                        </div>
                                    )
                                }
                            />

                        </Col>
                    </Row>


                </Container>

                <Modal
                    show={modalShow}
                    onHide={handleClose}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Delete?
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h4></h4>
                        <p>
                            Are you sure you want to delete <b>{deleteClient}</b>?
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={handleDeleteClient}>Yes</Button>
                        <Button variant="secondary" onClick={handleClose}>Close</Button>
                    </Modal.Footer>

                </Modal>
            </div>


        </div>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        onGetClientList: (token, page, search) => dispatch(actions.clientList(token, page, search)),
        onSetClient: (name) => dispatch(actions.setClient(name)),
        onDeleteClient: (token, name) => dispatch(actions.deleteClient(token, name)),
        onInitializeGetClient: () => dispatch(actions.initializeGetClient()),
        onInitializeAddClient: () => dispatch(actions.initializeAddClient()),
        onInitializeSaveClient: () => dispatch(actions.initializeSaveClient()),
        onInitializeDeleteClient: () => dispatch(actions.initializeDeleteClient()),
        onInitializeRetrieveCredits: () => dispatch(actions.initializeRetrieveCredits()),
        onDisplaySnackbar: (message) => dispatch(actions.showSuccessSnackbar(message))

    }
};

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        clients: state.clients.client_list,
        isLoading: state.clients.loading,
        total_clients: state.clients.total,
        current_page: state.clients.current_page,
        per_page: state.clients.per_page,
        total_pages: state.clients.total_pages,
        isSetLoginName: state.clientReducer.name,
        isDeleteClient: state.deleteClientReducer,
        searchClients: state.searchClients.search

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Clients);