import axios from 'axios';
import * as actionTypes from './actionTypes';

export const retrieveCreditsLoading = () => {
    return {
        type: actionTypes.RETRIEVE_CREDITS_LOADING
    }
};

export const retrieveCreditsFail = (error) => {
    return {
        type: actionTypes.RETRIEVE_CREDITS_FAIL,
        error: error
    }
};

export const retrieveCreditsSuccess = (data) => {
    return {
        type: actionTypes.RETRIEVE_CREDITS_SUCCESS,
        payload: data
    }
};

export const retrieveCreditsInitialize = () => {
    return {
        type: actionTypes.RETRIEVE_CREDITS_INITIALIZE
    }
};

export const initializeRetrieveCredits = () => {
    return dispatch => {
        dispatch(retrieveCreditsInitialize())
    }
};

export const retrieveCredits = (token, data) => {
    return dispatch => {
        dispatch(retrieveCreditsLoading());
        axios.post('/api/retrieve', {...data, token: token})
            .then(res => {
                dispatch(retrieveCreditsSuccess(res.data))
            }).catch(error => {
            //console.log('logging error: ', error.response.data);
            let message = "Server error, try again later";
            if (error.response){
                message = error.response.data.message;
            }

            dispatch(retrieveCreditsFail(message));
        })
    }
};

