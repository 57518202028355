import axios from 'axios';
import * as actionTypes from './actionTypes';

export const changePasswordLoading = () => {
    return {
        type: actionTypes.PASSWORD_CHANGE_LOADING
    }
};

export const changePasswordFail = (error) => {
    return {
        type: actionTypes.PASSWORD_CHANGE_FAIL,
        error: error
    }
};

export const changePasswordSuccess = (data) => {
    return {
        type: actionTypes.PASSWORD_CHANGE_SUCCESS,
        payload: data
    }
};

export const changePasswordInitialize = () => {
    return {
        type: actionTypes.PASSWORD_CHANGE_INITIALIZE
    }
};

export const initializeChangePassword = () => {
    return dispatch => {
        dispatch(changePasswordInitialize())
    }
};

export const changePasswordDealer = (token, data) => {
    return dispatch => {
        dispatch(changePasswordLoading());
        axios.post('/reseller/changepassword', {...data, token: token})
            .then(res => {
                dispatch(changePasswordSuccess(res.data))
            }).catch(error => {
            //console.log('logging error: ', error.response.data);
            let message = "Server error, try again later";
            if (error.response){
                message = error.response.data.message;
            }

            dispatch(changePasswordFail(message));
        })
    }
};

export const changePasswordReseller = (token, data) => {
    return dispatch => {
        dispatch(changePasswordLoading());
        axios.post('/superreseller/changepassword', {...data, token: token})
            .then(res => {
                dispatch(changePasswordSuccess(res.data))
            }).catch(error => {
            //console.log('logging error: ', error.response.data);
            let message = "Server error, try again later";
            if (error.response){
                message = error.response.data.message;
            }

            dispatch(changePasswordFail(message));
        })
    }
};

export const changePasswordSuperReseller = (token, data) => {
    return dispatch => {
        dispatch(changePasswordLoading());
        axios.post('/admin/changepassword', {...data, token: token})
            .then(res => {
                dispatch(changePasswordSuccess(res.data))
            }).catch(error => {
            //console.log('logging error: ', error.response.data);
            let message = "Server error, try again later";
            if (error.response){
                message = error.response.data.message;
            }

            dispatch(changePasswordFail(message));
        })
    }
};