import * as actionTypes from '../actions/actionTypes';

const initialState = {
    credits: null,
    loading: null,
    error: null
};

const reducer = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.CREDITS_LOADING:
            return {
                ...state,
                loading: true,
                error: null,
                credits: null
            };
        case actionTypes.CREDITS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error,
                credits: null
            };
        case actionTypes.CREDITS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                credits: action.payload.credits
            };
        default:
            return state;
    }
};

export default reducer;