import * as actionTypes from '../actions/actionTypes';

const initialState = {
    total: 0,
    total_clients: 0,
    date_of_total_clients: new Date(),
    super_reseller_list: [],
    loading: null,
    error: null
};

const reducer = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.SUPER_RESELLERS_LOADING:
            return {
                ...state,
                loading: true,
                error: null,
                super_reseller_list: []
            };
        case actionTypes.SUPER_RESELLERS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error,
                super_reseller_list: []
            };
        case actionTypes.SUPER_RESELLERS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                total: action.payload.count,
                total_clients: action.payload.totalClients,
                date_of_total_clients: action.payload.onDate,
                super_reseller_list: action.payload.data
            };
        default:
            return state;
    }
};

export default reducer;