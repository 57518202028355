import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import { Button, Form, Alert } from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { Redirect } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner'
import * as actions from "../store/actions";
import * as actionTypes from "../store/actions/actionTypes";
import SuperResellerClientTransactions from './SuperResellerClientTransactions';
import ResellerPassword from './ResellerPassword';

function UpdateReseller(props){

    const [reseller, setReseller] = useState(
        props.currentReseller
    );
    const [transaction, setTransaction] = useState(0);

    const [credits, setCredits] = useState(1);

    const [description, setDescription] = useState();

    useEffect(() => {
        //props.onInitializeSaveClient();
        props.onInitializeSaveReseller();
        props.onGetReseller(props.token, props.currentResellerName);

    }, []);

    useEffect(() => {
        setReseller(props.currentReseller);
        console.log('current reseller object', reseller);
    }, [props.currentReseller]);

    useEffect(() => {
        if (props.isErrorSaveReseller){
            var errorMessage = '' + props.isErrorSaveReseller;
            props.onDisplaySnackbar({type: actionTypes.SNACKBAR_TYPE_ERROR, message: errorMessage});
        }
    }, [props.isErrorSaveReseller]);


    const handleChange = (event) => {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        console.log('name', name);
        console.log('value', value);
        const key = target.key;
        setReseller({
            ...reseller,
            [name]: value
        })
    };

    const spinner = (
        <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
        />
    );

    let options = [...Array(100).keys()].slice(1);
    options = [...options, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000];


    function handleSubmit(event) {
        event.preventDefault();

        const data = {
            name: reseller.name,
            credits: credits,
            desc: reseller.description,
            transaction: transaction

        };
        console.log('logging data', data);
        props.onSaveReseller(props.token, data);

    }

    let saveResellerRedirect = null;
    if(props.isSuccessSaveReseller){
        console.log('redirecting because', props.isSuccessSaveReseller);
        props.onGetCredits(props.token);
        var successMessage = 'Updated ' + props.isSuccessSaveReseller.name;
        props.onDisplaySnackbar({type: actionTypes.SNACKBAR_TYPE_SUCCESS, message: successMessage});
        saveResellerRedirect = <Redirect to="/resellers" />
    }

    // let displayError = null;
    // if (props.isErrorSaveReseller){
    //     //displayError = <Alert variant="danger">{props.isErrorSaveReseller}</Alert>
    //     var errorMessage = '' + props.isErrorSaveReseller;
    //     props.onDisplaySnackbar({type: actionTypes.SNACKBAR_TYPE_ERROR, message: errorMessage});
    // }

    return (
        <div>
            {saveResellerRedirect}
            <Container>
                <Row>
                <Col md={3}>
                    <Card>
                    <Card.Header as="h5">Update {props.currentReseller.name}</Card.Header>
                    <Card.Body>
                        <h5>{props.currentReseller.credits} Credits</h5>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="transaction">
                                <Form.Control
                                    as="select"
                                    onChange={e => setTransaction(e.target.value)}
                                >
                                    <option value={0} >Add</option>
                                    <option value={1} >Remove</option>

                                </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="credits">
                                <Form.Control
                                    as="select"
                                    onChange={e => setCredits(e.target.value)}
                                >
                                    {options.map(option => {
                                        return <option value={option}>{option}</option>
                                    })}

                                </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="description" >
                                <Form.Control
                                    value={reseller.description}
                                    name="description"
                                    placeholder="Description"
                                    onChange={handleChange}
                                    type="text"
                                />
                            </Form.Group>
                            <Button
                                block
                                disabled={props.isLoadingSaveReseller}
                                type="submit"
                            >
                                {props.isLoadingSaveReseller
                                    ? spinner
                                    : 'Submit'}
                            </Button>
                        </Form>
                    </Card.Body>
                    </Card>
                </Col>
                    <Col md={9}>
                        <Row>
                            <Col md={12} mb={5}>
                                <Card>
                                    <Card.Header as="h5">Change password</Card.Header>
                                    <Card.Body><ResellerPassword /></Card.Body>
                                </Card>
                            </Col>
                            <Col md={12} mb={5}>
                                <Card>
                                    <Card.Header as="h5">Transactions</Card.Header>
                                    <Card.Body><SuperResellerClientTransactions /></Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

        </div>
    )

}

const mapDispatchToProps = dispatch => {
    return {
        onGetReseller: (token, name) => dispatch(actions.getReseller(token, name)),
        onInitializeSaveReseller: () => dispatch(actions.initializeSaveReseller()),
        onSaveReseller: (token, data) => dispatch(actions.saveReseller(token, data)),
        onGetCredits: (token) => dispatch(actions.creditsSuperReseller(token)),
        onDisplaySnackbar: (message) => dispatch(actions.showSuccessSnackbar(message))
    }
};

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        currentResellerName: state.reseller.name,
        currentReseller: state.reseller.item,
        isLoadingReseller: state.reseller.loading,
        isErrorSaveReseller: state.saveReseller.error,
        isSuccessSaveReseller: state.saveReseller.reseller,
        isLoadingSaveReseller: state.saveReseller.loading

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateReseller);