import * as actionTypes from '../actions/actionTypes';

const initialState = {
    search: null
};

const reducer = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.SEARCH_SAVE:
            return {
                ...state,
                search: action.payload,
            };

        case actionTypes.SEARCH_RESET:
            return {
                ...state,
                search: null
            };
        default:
            return state;
    }
};

export default reducer;