import React, {useState, useEffect} from "react";
import {connect} from 'react-redux';
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import * as actions from "../store/actions";
import * as actionTypes from "../store/actions/actionTypes";

function QuickRenew(props) {

    const [show, setShow] = useState(false);
    const [months, setMonths] = useState("");

    useEffect(() => {
        props.onInitializeRenewCredits();
    }, []);

    useEffect(() => {
        if (props.isFailRenewCredits){
            console.log('logging isfailretrieve credits', props.isFailRenewCredits);
            var errorMessage = '' + props.isFailRenewCredits;
            props.onDisplaySnackbar({type: actionTypes.SNACKBAR_TYPE_ERROR, message: errorMessage});
        }
    }, [props.isFailRenewCredits]);

    const spinner = (
        <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
        />
    );

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleSave = () => {
        console.log('logging months', months);
        const data = {
            name: props.name,
            months: months

        };
        console.log('logging data', data);
        props.onRenewCredits(props.token, data);
        setShow(false);
    };

    if(props.isSuccessRenewCredits){
        console.log('redirecting because', props.isSuccessRenewCredits);
        props.onGetCredits(props.token);
        props.onInitializeRenewCredits();
        props.onGetClientList(props.token, props.current_page);
        var successMessage = 'Renewed ' + props.isSuccessRenewCredits.months + ' month(s) for ' + props.isSuccessRenewCredits.name;
        props.onDisplaySnackbar({type: actionTypes.SNACKBAR_TYPE_SUCCESS, message: successMessage});
    }

    function validateForm() {
        return months != 0;
    }


    return (
        <>
            <Button variant="outline-success" onClick={handleShow}>
                Renew
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Renew {props.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Form>
                    <Form.Group controlId="months">
                        <Form.Control
                            as="select"
                            onChange={e => setMonths(e.target.value)}
                        >
                            <option value={0}>Add months</option>
                            <option value={1}>1 Month</option>
                            <option value={2}>2 Months</option>
                            <option value={3}>3 Months</option>
                            <option value={4}>4 Months</option>
                            <option value={5}>5 Months</option>
                            <option value={6}>6 Months</option>
                            <option value={7}>7 Months</option>
                            <option value={8}>8 Months</option>
                            <option value={9}>9 Months</option>
                            <option value={10}>10 Months</option>
                            <option value={11}>11 Months</option>
                            <option value={12}>12 Months</option>
                        </Form.Control>
                    </Form.Group>
                </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleSave}
                        disabled={!validateForm() || props.isLoadingRenewCredits}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        onRenewCredits: (token, data) => dispatch(actions.renewCredits(token, data)),
        onInitializeRenewCredits: () => dispatch(actions.initializeRenewCredits()),
        onGetCredits: (token) => dispatch(actions.creditsUser(token)),
        onGetClientList: (token, page) => dispatch(actions.clientList(token, page)),
        onDisplaySnackbar: (message) => dispatch(actions.showSuccessSnackbar(message))

    }
};

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        current_page: state.clients.current_page,
        isSuccessRenewCredits: state.renewCredits.credits,
        isLoadingRenewCredits: state.renewCredits.loading,
        isFailRenewCredits: state.renewCredits.error
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(QuickRenew);