import axios from 'axios';
import * as actionTypes from './actionTypes';

export const getMessageLoading = () => {
    return {
        type: actionTypes.GET_MESSAGE_LOADING
    }
};

export const getMessageFail = (error) => {
    return {
        type: actionTypes.GET_MESSAGE_FAIL,
        error: error
    }
};

export const getMessageSuccess = (data) => {
    return {
        type: actionTypes.GET_MESSAGE_SUCCESS,
        payload: data
    }
};

export const saveMessageLoading = () => {
    return {
        type: actionTypes.SAVE_MESSAGE_LOADING
    }
};

export const saveMessageFail = (error) => {
    return {
        type: actionTypes.SAVE_MESSAGE_FAIL,
        error: error
    }
};

export const saveMessageSuccess = (data) => {
    return {
        type: actionTypes.SAVE_MESSAGE_SUCCESS,
        payload: data
    }
};

export const initializeMessage = () => {
    return {
        type: actionTypes.SAVE_MESSAGE_INITIALIZE
    }
};

export const getMessage = (token) => {
    return dispatch => {
        dispatch(getMessageLoading());
        axios.get('/admin/message?token=' + token )
            .then(res => {
                console.log(res);
                dispatch(getMessageSuccess(res.data));
            }).catch(error => {
                let message = "Server error, try again later";
                if (error.response){
                    message = error.response.data.message;
                }
                dispatch(getMessageFail(message));
        });
    }
};

export const getMessageSuperReseller = (token) => {
    return dispatch => {
        dispatch(getMessageLoading());
        axios.get('/superreseller/message?token=' + token )
            .then(res => {
                console.log(res);
                dispatch(getMessageSuccess(res.data));
            }).catch(error => {
                let message = "Server error, try again later";
                if (error.response){
                    message = error.response.data.message;
                }
                dispatch(getMessageFail(message));
        });
    }
};

export const getMessageReseller = (token) => {
    return dispatch => {
        dispatch(getMessageLoading());
        axios.get('/reseller/message?token=' + token )
            .then(res => {
                console.log(res);
                dispatch(getMessageSuccess(res.data));
            }).catch(error => {
            let message = "Server error, try again later";
                if (error.response){
                    message = error.response.data.message;
                }
                dispatch(getMessageFail(message));
        });
    }
};

export const getMessageDealer = (token) => {
    return dispatch => {
        dispatch(getMessageLoading());
        axios.get('/api/message?token=' + token )
            .then(res => {
                console.log(res);
                dispatch(getMessageSuccess(res.data));
            }).catch(error => {
            let message = "Server error, try again later";
            if (error.response){
                message = error.response.data.message;
            }
            dispatch(getMessageFail(message));
        });
    }
};

export const initializeSaveMessage = () => {
    return dispatch => {
        dispatch(initializeMessage());
    }
};

export const saveMessage = (token, data) => {
    return dispatch => {
        dispatch(saveMessageLoading());
        axios.post('/admin/message', {...data, token: token})
            .then(res => {
                console.log(res);
                dispatch(saveMessageSuccess(res.data));
            }).catch(error => {
            let message = "Server error, try again later";
            if (error.response){
                message = error.response.data.message;
            }
            dispatch(saveMessageFail(message));
        });
    }
};