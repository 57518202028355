import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import './App.css';
import { withRouter } from "react-router-dom";
import { Navbar } from "react-bootstrap";
import { Nav, NavItem} from "react-bootstrap";
import { NavLink, Link } from 'react-router-dom';
import {LinkContainer} from 'react-router-bootstrap';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Routes from "./Routes";
import NavItems from "./containers/navItems";
import CreditsNav from "./containers/CreditsNav";
import * as actions from './store/actions/index';
import DisplaySnackbar from './containers/DisplaySnackbar';

//import 'bootstrap';

function App(props) {

    useEffect(()=> {
        props.onTryAutoSignin();
    },[]);

    return (
        <div className="App">
            <DisplaySnackbar />
            <Navbar bg="light" variant="light" expand="lg">
                <Navbar.Brand href="#home">Panel</Navbar.Brand>
                <CreditsNav />
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <NavItems />
                </Navbar.Collapse>
            </Navbar>
            <Routes />
        </div>
    );
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
        role: state.auth.role
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onTryAutoSignin: () => dispatch(actions.checkAuthState())
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
