import React, {useState, useEffect, useLayoutEffect} from "react";
import {connect} from 'react-redux';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Jumbotron from 'react-bootstrap/Jumbotron'
import Alert from 'react-bootstrap/Alert';

import * as actions from '../store/actions/index';


function ResellerDashboard(props) {

    useEffect(() => {
        console.log('logging dashboard reseller list');
        props.onGetDealerList(props.token);
        props.onGetCredits(props.token);
        props.onGetMessage(props.token);
    }, []);

    return (
        <div>
            <div className="lander">
                <h3>Dashboard</h3>
                <Container>
                    <Row>
                        <Col>
                            <Alert variant="info">{props.message}</Alert>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div><h3>{props.credits}</h3></div>
                            <div><h4>Credits</h4></div>
                        </Col>
                        <Col>
                            <div><h3>{props.total}</h3></div>
                            <div><h4>Dealers</h4></div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        onGetDealerList: (token) => dispatch(actions.dealerList(token)),
        onGetCredits: (token) => dispatch(actions.creditsReseller(token)),
        onGetMessage: (token) => dispatch(actions.getMessageReseller(token))
    }
};

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        credits: state.credits.credits,
        total: state.dealers.total,
        message: state.getMessage.message
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ResellerDashboard);