import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import { Button, Form, Alert } from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Redirect } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import PhoneInput from 'react-phone-number-input/input';
import MaskedInput from 'react-text-mask';
import Card from 'react-bootstrap/Card';
import * as actions from "../store/actions";
import * as actionTypes from "../store/actions/actionTypes";


function AddClient(props){

    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const [macAddress, setMacAddress] = useState("00:1A:79:");
    const [ministra, setMinistra] = useState(false);
    const [months, setMonths] = useState("");
    const [description, setDescription] = useState("");
    const [phone, setPhone] = useState("");
    const [packageSelection, setPackageSelection] = useState("");
    const [packagesList, setPackagesList] = useState(
        props.packages
    );

    useEffect(() => {
        console.log('inside addclient');
        props.onInitializeAddClient();
        props.onGetPackageList(props.token);
    }, []);

    useEffect(()=>{
       setPackagesList(props.packages);
    }, [props.packages]);

    useEffect(() => {
        if (props.isErrorAddClient){
            var errorMessage = '' + props.isErrorAddClient;
            props.onDisplaySnackbar({type: actionTypes.SNACKBAR_TYPE_ERROR, message: errorMessage});
        }
    }, [props.isErrorAddClient]);


    //const handleToggle = ({target}) =>
    //    setPackagesList(s => ({ ...s, [target.name]: !s[target.name]}));

    const spinner = (
        <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
        />
    );

    const handleToggle = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const key = target.key;
        setPackagesList({
            ...packagesList,
            [name]: {
                ...packagesList[name],
                checked: value
            }
        })
    };

    function validateForm() {
        if (ministra){
            return login.length > 0 && login.length < 15 && password.length > 0 && password.length < 15 && description.length < 50 && phone.length < 15;
        } else {
            return macAddress.length === 17 && login.length > 0 && login.length < 15 && password.length > 0 && password.length < 15 && description.length < 50 && phone.length < 15;
        }

    }

    function handleSubmit(event) {
        event.preventDefault();
        console.log("login", login);
        console.log("password", password);
        console.log("macaddress", macAddress);
        //console.log("ministra", ministra);
        console.log("months", months);
        console.log("description", description);
        console.log("phone", phone);

        const data = {
            login: login,
            password: password,
            stb_mac: macAddress,
            ministra: ministra,
            months: months,
            desc: description,
            phone: phone,
            package_selection: packageSelection,
            packages: packagesList
        };
        //console.log(data);
        props.onAddClient(props.token, data);
    }

    let addClientRedirect = null;
    if(props.isSuccessAddClient){
        props.onGetCredits(props.token);
        var successMessage = 'Added ' + props.isSuccessAddClient.name;
        props.onDisplaySnackbar({type: actionTypes.SNACKBAR_TYPE_SUCCESS, message: successMessage});
        addClientRedirect = <Redirect to="/clients" />
    }

    // let displayError = null;
    // if (props.isErrorAddClient){
    //     displayError = <Alert variant="danger">{props.isErrorAddClient}</Alert>
    // }
    /*
                        <option value={1}>All Packages</option>
                        <option value={2}>English/Sports/Adults</option>
                        <option value={3}>South Asian/English/Sports/Adult</option>
                        <option value={4}>South Asian/English/Sports/No Adult</option>
                        <option value={5}>English/Portuguese/Brazil/Sports/Adult</option>
                        <option value={6}>Custom</option>

                        <option value={1}>All Packages</option>
                        <option value={4}>English/Spanish/Portuguese/Brazil/Sports/No Adult</option>
                        <option value={5}>English/Spanish/Portuguese/Brazil/Sports/Adult</option>
                        <option value={6}>Custom</option>
     */


    let displayCustomPackages = null;
    if (packageSelection == 6){
        displayCustomPackages = (<div className="mb-3">

            {Object.keys(packagesList).map((key,value) => {
                return(<Form.Check
                    type="checkbox"
                    onChange={handleToggle}
                    key={key}
                    id={key}
                    name={key}
                    label={packagesList[key].name}
                    checked={packagesList[key].checked}
                />);
            })}
            {console.log('packages list: ', packagesList)}
            {console.log('package selection: ', packageSelection)}

        </div>)
    }

    let displayMacAddress = null;
    console.log('logging ministra: ', ministra);
    if (!ministra){
        displayMacAddress = (<Form.Group>
            <MaskedInput
                mask={['0', '0', ':' , '1', 'A', ':' , '7', '9', ':', /[A-Fa-f0-9]/, /[A-Fa-f0-9]/, ':' , /[A-Fa-f0-9]/, /[A-Fa-f0-9]/, ':' , /[A-Fa-f0-9]/, /[A-Fa-f0-9]/]}
                className="form-control"
                placeholder="Mac Address"
                value={macAddress}
                guide={true}
                id="macAddress"
                onChange={e => setMacAddress(e.target.value)}
            />
        </Form.Group>);
    }

    return (
        <div>
            {addClientRedirect}

            <Container fluid>
            <Card>
                <Card.Header as="h5">Add client</Card.Header>
                <Card.Body>
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="login" >
                    <Form.Control
                        autoFocus
                        type="text"
                        value={login}
                        placeholder="Enter login name"
                        onChange={e => setLogin(e.target.value)}
                    />
                </Form.Group>
                <Form.Group controlId="password" >
                    <Form.Control
                        value={password}
                        placeholder="Enter password"
                        onChange={e => setPassword(e.target.value)}
                        type="text"
                    />
                </Form.Group>
                <Form.Group controlId="description" >
                    <Form.Control
                        value={description}
                        placeholder="Description"
                        onChange={e => setDescription(e.target.value)}
                        type="text"
                    />
                </Form.Group>

                <Form.Group>
                    <MaskedInput
                        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                        className="form-control"
                        placeholder="Enter a phone number"
                        value={phone}
                        guide={true}
                        id="phone"
                        onChange={e => setPhone(e.target.value)}
                    />
                </Form.Group>
                <div className="mb-3">
                    <Form.Check
                        type="checkbox"
                        onChange={e => setMinistra(e.target.checked)}
                        key="ministra"
                        id="ministra"
                        name="ministra"
                        label="Ministra"
                        checked={ministra}
                    />
                </div>
                {displayMacAddress}

                <Form.Group controlId="months">
                    <Form.Control
                        as="select"
                        onChange={e => setMonths(e.target.value)}
                    >
                        <option value={0}>Trial - Expires at midnight</option>
                        <option value={"2d"}>Trial - Expires tomorrow midnight</option>
                        <option value={1}>1 Month</option>
                        <option value={2}>2 Months</option>
                        <option value={3}>3 Months</option>
                        <option value={4}>4 Months</option>
                        <option value={5}>5 Months</option>
                        <option value={6}>6 Months</option>
                        <option value={7}>7 Months</option>
                        <option value={8}>8 Months</option>
                        <option value={9}>9 Months</option>
                        <option value={10}>10 Months</option>
                        <option value={11}>11 Months</option>
                        <option value={12}>12 Months</option>
                    </Form.Control>
                </Form.Group>
                <Form.Group controlId="packageSelection">
                    <Form.Control
                        as="select"
                        onChange={e => setPackageSelection(e.target.value)}
                    >
                        <option value={1}>All Packages</option>
                        <option value={7}>All Packages/No Adults</option>
                        <option value={2}>English/Sports/Adults</option>
                        <option value={3}>South Asian/English/Sports/Adults</option>
                        <option value={4}>South Asian/English/Sports/No Adults</option>
                        <option value={5}>English/Portuguese/Brazil/Sports/Adults</option>
                        <option value={13}>English/Sports/No Adults</option>
                        <option value={14}>English/Sports/Portuguese/Brasil/Adults</option>
                        <option value={15}>English/Sports/Portuguese/Brasil/No Adults</option>
                        <option value={16}>English/Sports/Spanish/Adults</option>
                        <option value={17}>English/Sports/Spanish/No Adults</option>
                        <option value={18}>English/Sports/Portuguese/Brazilian/Italian/Spanish/South America/Latino/Adults</option>
                        <option value={19}>English/Sports/Portuguese/Brazilian/Italian/Spanish/South America/Latino/No Adults</option>
                        <option value={20}>English/Sports/Spanish/Portuguese/Italian/Adults</option>
                        <option value={21}>English/Sports/Spanish/Portuguese/Italian/No Adults</option>
                        <option value={22}>English/Sports/Caribbean/Adults</option>
                        <option value={23}>English/Sports/Caribbean/No Adults</option>
                        <option value={24}>English/Sports/Caribbean/South Asian/No Adults</option>
                        <option value={25}>English/Sports/Caribbean/South Asian/Adults</option>
                        <option value={26}>English/Sports/Ex Yugoslavia/Adults</option>
                        <option value={27}>English/Sports/Ex Yugoslavia/No Adults</option>
                        <option value={28}>English/Sports/Portuguese/Brasil/Italian/Spanish/Adults</option>
                        <option value={32}>English/Sports/Portuguese/Brasil/Italian/Spanish/No Adults</option>
                        <option value={33}>English/Sports/Italian/Adults</option>
                        <option value={34}>English/Sports/Italian/No Adults</option>
                        <option value={35}>English/Sports/Latino/Mexico/Spain/South America/Adults</option>
                        <option value={36}>English/Sports/Latino/Mexico/Spain/South America/No Adults</option>
                        <option value={37}>English/Sports/Arabic/No Adults</option>
                        <option value={38}>English/Sports/Arabic/Adults</option>
                        <option value={39}>English/Sports/Greek/Adults</option>
                        <option value={40}>English/Sports/Greek/No Adults</option>
                        <option value={41}>English/Sports/Polish/Romania/Ex Yugoslavia/Adults</option>
                        <option value={42}>English/Sports/Polish/Romania/Ex Yugoslavia/No Adults</option>
                        <option value={43}>English/Sports/French CA/African/French/No Adults</option>
                        <option value={44}>English/Sports/French CA/African/French/Adults</option>
                        <option value={45}>English/Sports/German/No Adults</option>
                        <option value={46}>English/Sports/German/Adults</option>
                        <option value={47}>English/Sports/Vietnamese/Korean/Chinese/Filipino/No Adults</option>
                        <option value={48}>English/Sports/Vietnamese/Korean/Chinese/Filipino/Adults</option>
                        <option value={49}>English/Sports/Hindi/Urdu/Punjabi/Adults</option>
                        <option value={50}>English/Sports/Hindi/Urdu/Punjabi/No Adults</option>
                    </Form.Control>
                </Form.Group>
                {displayCustomPackages}
                <Button
                    block
                    disabled={!validateForm() || props.isLoadingAddClient}
                    type="submit"
                >
                    {props.isLoadingAddClient
                        ? spinner
                        : 'Submit'}
                </Button>
            </Form>
                </Card.Body>
            </Card>
            </Container>
        </div>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        onGetPackageList: (token) => dispatch(actions.packagesList(token)),
        onInitializeAddClient: () => dispatch(actions.initializeAddClient()),
        onAddClient: (token, data) => dispatch(actions.addClient(token, data)),
        onGetCredits: (token) => dispatch(actions.creditsUser(token)),
        onDisplaySnackbar: (message) => dispatch(actions.showSuccessSnackbar(message))
    }
};

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        packages: state.packages.package_list,
        isLoading: state.packages.loading,
        isLoadingAddClient: state.addClient.loading,
        isErrorAddClient: state.addClient.error,
        isSuccessAddClient: state.addClient.client
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(AddClient);