import React, {useState, useEffect, useLayoutEffect} from "react";
import {connect} from 'react-redux';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import * as actions from '../store/actions/index';


function ResellerDashboard(props) {

    useEffect(() => {
        console.log('logging dashboard reseller list');
        props.onGetSuperResellerList(props.token);
        props.onInitializeSaveMessage();
    }, []);

    return (
        <div>
            <div className="lander">
                <h3>Dashboard</h3>
                <Container>
                    <Row>
                        <Col>
                            <div><h3>Unlimited</h3></div>
                            <div><h4>Credits</h4></div>
                        </Col>
                        <Col>
                            <div><h3>{props.total}</h3></div>
                            <div><h4>Super Resellers</h4></div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        onGetSuperResellerList: (token) => dispatch(actions.superResellerList(token)),
        onInitializeSaveMessage: () => dispatch(actions.initializeSaveMessage())
    }
};

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        total: state.superResellers.total
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ResellerDashboard);