import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import { Button, Form, Alert } from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Redirect } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner'
import * as actions from "../store/actions";
import * as actionTypes from "../store/actions/actionTypes";


function AdminMessage(props){

    const [message, setMessage] = useState("");

    useEffect(() => {
        console.log('inside message');
        props.onGetMessage(props.token);
        props.onInitializeSaveMessage();
    }, []);

    //const handleToggle = ({target}) =>
    //    setPackagesList(s => ({ ...s, [target.name]: !s[target.name]}));

    const spinner = (
        <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
        />
    );

    function validateForm() {
        return message.length > 0;
    }

    function handleSubmit(event) {
        event.preventDefault();
        console.log("login", message);

        const data = {
            message: message

        };
        props.onSaveMessage(props.token, data);
    }

    let saveMessageRedirect = null;
    if(props.isSuccessSaveMessage){
        props.onDisplaySnackbar({type: actionTypes.SNACKBAR_TYPE_SUCCESS, message: 'Message saved.'});
        saveMessageRedirect = <Redirect to="/" />
    }

    let displayError = null;
    if (props.isErrorSaveMessage){
        //displayError = <Alert variant="danger">{props.isErrorSaveMessage}</Alert>
        props.onDisplaySnackbar({type: actionTypes.SNACKBAR_TYPE_ERROR, message: 'Error saving message.'});
    }


    return (
        <div>
            {saveMessageRedirect}
            <h3>Update Message</h3>
            <Container>
                {displayError}
                <Alert variant="info">{props.currentMessage}</Alert>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="message" >
                        <Form.Control
                            autoFocus
                            type="text"
                            value={message}
                            placeholder="Enter a new message"
                            onChange={e => setMessage(e.target.value)}
                        />
                    </Form.Group>

                    <Button
                        block
                        disabled={!validateForm() || props.isLoadingSaveMessage}
                        type="submit"
                    >
                        {props.isLoadingSaveMessage
                            ? spinner
                            : 'Submit'}
                    </Button>
                </Form>
            </Container>
        </div>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        onInitializeSaveMessage: () => dispatch(actions.initializeSaveMessage()),
        onGetMessage: (token) => dispatch(actions.getMessage(token)),
        onSaveMessage: (token, data) => dispatch(actions.saveMessage(token, data)),
        onDisplaySnackbar: (message) => dispatch(actions.showSuccessSnackbar(message))
    }
};

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        currentMessage: state.getMessage.message,
        isLoadingSaveMessage: state.saveMessage.loading,
        isErrorSaveMessage: state.saveMessage.error,
        isSuccessSaveMessage: state.saveMessage.message

    }
};

export default connect(mapStateToProps,mapDispatchToProps)(AdminMessage);