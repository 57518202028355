import * as actionTypes from '../actions/actionTypes';

const initialState = {
    item: {
        name: '',
        password: '',
        created: null,
        credits: 0,
        description: '',
        transaction: 0
    },
    name: null,
    loading: null,
    error: null
};

const reducer = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.SET_CURRENT_SUPER_RESELLER:
            return {
                ...state,
                loading: null,
                error: null,
                item: {
                    ...state.item,
                    name: '',
                    password: '',
                    created: null,
                    credits: 0,
                    description: '',
                    transaction: 0},
                name: action.payload
            };
        case actionTypes.GET_SUPER_RESELLER_INITIALIZE:
            return {
                ...state,
                loading: null,
                error: null,
                item: {
                    ...state.item,
                    name: '',
                    password: '',
                    created: null,
                    credits: 0,
                    description: '',
                    transaction: 0},
                name: null
            };
        case actionTypes.GET_SUPER_RESELLER_LOADING:
            return {
                ...state,
                loading: true,
                error: null,
                item: {
                    ...state.item,
                    name: '',
                    password: '',
                    created: null,
                    credits: 0,
                    description: '',
                    transaction: 0}

            };
        case actionTypes.GET_SUPER_RESELLER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error,
                item: {
                    ...state.item,
                    name: '',
                    password: '',
                    created: null,
                    credits: 0,
                    description: '',
                    transaction: 0}

            };
        case actionTypes.GET_SUPER_RESELLER_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                item: {
                    ...state.item,
                    ...action.payload}
            };
        default:
            return state;
    }
};

export default reducer;