import React, {useState, useEffect, useLayoutEffect, useMemo} from 'react';
//import { MDBDataTable } from 'mdbreact';
import DataTable from 'react-data-table-component';
import * as actions from "../store/actions";
import {connect} from "react-redux";
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import moment from 'moment';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import styled from 'styled-components';
import * as actionTypes from "../store/actions/actionTypes";

function ReactTable(props) {

    const [myData, setMyData] = useState();
    const [dataRows, setDataRows] = useState();
    const [filterText, setFilterText] = useState('');
    const [filterData, setFilterData] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    useEffect(() => {
        //console.log('logging dashboard client list');
        //props.onInitializeGetClient();
        //props.onInitializeSaveClient();
        //props.onInitializeAddClient();
        //props.onInitializeDeleteClient();
        //props.onInitializeRetrieveCredits();
        //props.onGetClientList(props.token, 1, props.searchClients);

    }, []);

    const TextField = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;

  &:hover {
    cursor: pointer;
  }
`;
    const ClearButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;
    const FilterComponent = ({ filterText, onFilter, onClear }) => (
        <>
            <TextField id="search" type="text" placeholder="Search" value={filterText} onChange={onFilter} />
            <ClearButton onClick={onClear}>X</ClearButton>
        </>
    );

    //const filteredItems = dataRows.filter(item => item.name && item.name.includes(filterText));

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        //return <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
        //return <input id="search" type="text" placeholder="Search" value={filterText} onChange={e => setFilterText(e.target.value)} />;
    }, [filterText, resetPaginationToggle]);

    let loading = (

        <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
        </Spinner>
    );

    console.log('filter text', filterText);
    const sortIcon = <ArrowDownward />;

    let clientsData = [];

    /*    if (props.isLoading){
            console.log('inside datatable loading', props.isLoading);
            console.log('datatable', props.clients);
            clientsData = props.clients.map((client) => {
                console.log('inside client map', client);
                return {
                    login: 'ab',
                    mac: 'ab',
                    expiry: 'ab',
                    renew: 'ab',
                    status: 'ab',
                    delete: 'cd'
                };
            });
        }*/
    let data2 = {};
    let loadedData = null;

    useEffect(() => {
        if (props.clients && props.clients.length){
            clientsData = props.clients.map((client) => {
                //console.log('inside client map', client);
                return {
                    login: client.name,
                    mac: client.mac,
                    expiry: moment(client.expiry).format('ddd, MMM Do YYYY'),
                    renew: 'ab',
                    status: client.status ? "Active" : "Inactive",
                    delete: 'cd'
                };
            });
            console.log('logging clients data inside useeffect', clientsData);
            data2 = {
                columns: [
                    {
                        name: 'Login',
                        selector: 'login',
                        sortable: true,
                        width: 150
                    },
                    {
                        name: 'Mac',
                        selector: 'mac',
                        sortable: true,
                        width: 150
                    },
                    {
                        name: 'Expiry',
                        selector: 'expiry',
                        sortable: true,
                        width: 200
                    },
                    {
                        name: 'Renew',
                        selector: 'renew',
                        sortable: false,
                        width: 100
                    },
                    {
                        name: 'Status',
                        selector: 'status',
                        sortable: true,
                        width: 150
                    },
                    {
                        name: 'Delete',
                        selector: 'delete',
                        sortable: false,
                        width: 100
                    }
                ],

                rows: clientsData
            };
            loadedData = <DataTable
                title="Clients List"
                columns={data2.columns}
                data={data2.rows}
                pagination
                paginationResetDefaultPage={resetPaginationToggle}
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                sortIcon={sortIcon}
                striped
            />;
            console.log('logging data2 insidue useEffect', data2);
            console.log('logging props clients length', props.clients.length);
            console.log('loaded data', loadedData);
            setMyData(data2);
            setDataRows(clientsData);
            setFilterData(clientsData);
        }

    },[props.clients]);

    useEffect(() => {
        console.log('insided useEffect', filterText);
        if (dataRows && dataRows.length){
            console.log('filter data', clientsData);
            let data = dataRows.filter(item => {
                if(item.login && item.login.toLowerCase().includes(filterText.toLowerCase())){
                    return true;
                } else if (item.mac && item.mac.toLowerCase().includes(filterText.toLowerCase())) {
                    return true;
                } else if (item.status && item.status.toLowerCase().includes(filterText.toLowerCase())) {
                    return true;
                } else {
                    return false;
                }
            });
            setFilterData(data);
        }

    }, [filterText]);

    const dataColumns = [
        {
            name: 'Login',
            selector: 'login',
            sortable: true,
            width: 150
        },
        {
            name: 'Mac',
            selector: 'mac',
            sortable: true,
            width: 150
        },
        {
            name: 'Expiry',
            selector: 'expiry',
            sortable: true,
            width: 200
        },
        {
            name: 'Renew',
            selector: 'renew',
            sortable: true,
            width: 100
        },
        {
            name: 'Status',
            selector: 'status',
            sortable: true,
            width: 150
        },
        {
            name: 'Delete',
            selector: 'delete',
            sortable: true,
            width: 100
        }
    ];

    console.log('props clients::',props.clients);
    console.log('clientsdata:', clientsData);
    console.log('data2', data2);

    return (
        <div>
            <div className="text-right">
                <input id="search" type="text" placeholder="Search" value={filterText} onChange={e => setFilterText(e.target.value)} />
            </div>
            <DataTable
                columns={dataColumns}
                data={filterData}
                pagination
                paginationResetDefaultPage={resetPaginationToggle}
                sortIcon={sortIcon}
                striped
            />

        </div>

    );
}

const mapDispatchToProps = dispatch => {
    return {
        onGetClientList: (token, page, search) => dispatch(actions.clientList(token, page, search)),

    }
};

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        clients: state.clients.client_list,
        isLoading: state.clients.loading,
        total_clients: state.clients.total,
        current_page: state.clients.current_page,
        per_page: state.clients.per_page,
        total_pages: state.clients.total_pages,
        isSetLoginName: state.clientReducer.name,
        isDeleteClient: state.deleteClientReducer,
        searchClients: state.searchClients.search
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ReactTable);
