import axios from 'axios';
import * as actionTypes from './actionTypes';



export const getStatisticsLoading = () => {
    return {
        type: actionTypes.STATISTICS_LOADING
    }
};

export const getStatisticsFail = (error) => {
    return {
        type: actionTypes.STATISTICS_FAIL,
        error: error
    }
};

export const getStatisticsSuccess = (data) => {
    return {
        type: actionTypes.STATISTICS_SUCCESS,
        payload: data
    }
};

export const getAdminStatistics = (token, login) => {
    return dispatch => {
        dispatch(getStatisticsLoading());
        axios.get('/admin/statistics/' + login + '?token=' + token )
            .then(res => {
                console.log(res);
                dispatch(getStatisticsSuccess(res.data));
            }).catch(error => {
            let message = "Server error, try again later";
            if (error.response){
                message = error.response.data.message;
            }
            dispatch(getStatisticsFail(message));
        });
    }
};







