import React, { useState, useEffect } from "react";
import { Button, Form, Alert } from "react-bootstrap";
import {connect, useSelector} from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import moment from 'moment';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import DataTable from 'react-data-table-component';
import TextField from '@material-ui/core/TextField';
import * as actions from '../store/actions/index';
import * as actionTypes from '../store/actions/actionTypes';


function ResellerTransactions(props) {

    const [dataRows, setDataRows] = useState();
    const [filterText, setFilterText] = useState('');
    const [filterData, setFilterData] = useState('');

    useEffect(()=>{
        props.onGetResellerTransactions(props.token);
    },[]);

    const spinner = (
        <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
        />
    );

    const composeTransactionMessage = (t) => {
        let desc = '';
        if ('description' in t){
            desc = ' :: ' + t.description;
        }
        let message = '';

        if (t.type === 'RECEIVED' || t.type === 'RETURNED') {
            message = t.type + ' ' + t.credits + ' credit(s)';
        } else {
            message = t.type + ' ' + t.credits + ' credit(s), ' + t.login + desc;
        }

        return <p><b>{moment(t.created).format('MMM Do YYYY hh:mm a')}</b>: {message}</p>
    };

    let transactionsData = [];

    useEffect(() => {
        if (props.transactions && props.transactions.length){
            transactionsData = props.transactions.map((t) => {
                //console.log('inside client map', client);
                return {
                    date: moment(t.created).format('YYYY-MM-DD hh:mm a'),
                    type: t.type,
                    //mac: client.mac,
                    login: t.login,
                    credits: t.credits
                };
            });
            setDataRows(transactionsData);
            setFilterData(transactionsData);
        }
    },[props.transactions]);

    const sortIcon = <ArrowDownward />;

    const dataColumns = [
        {
            name: 'Date',
            selector: 'date',
            sortable: true,
            width: '170px'
        },
        {
            name: 'Type',
            selector: 'type',
            sortable: true,
            width: 100
        },
        {
            name: 'Credits',
            selector: 'credits',
            sortable: true,
            width: '70px'
        },
        {
            name: 'Login',
            selector: 'login',
            sortable: true,
            width: 200
        }

    ];

    useEffect(() => {
        //console.log('insided useEffect', filterText);
        if (dataRows && dataRows.length){
            //console.log('filter data', clientsData);
            let data = dataRows.filter(item => {
                if(item.login && item.login.toLowerCase().includes(filterText.toLowerCase())){
                    return true;
                } else if (item.type && item.type.toLowerCase().includes(filterText.toLowerCase())) {
                    return true;
                } else {
                    return false;
                }
            });
            setFilterData(data);
        }

    }, [filterText]);

    return (
        <Container>
            <Card>
                <Card.Header as="h5">Transactions</Card.Header>
                <Card.Body>
                    <DataTable
                        columns={dataColumns}
                        data={filterData}
                        pagination
                        sortIcon={sortIcon}
                        striped
                        noHeader={true}
                        subHeader
                        subHeaderComponent={
                            (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <TextField id="outlined-basic" label="Search" variant="outlined" size="small" style={{ margin: '5px' }} value={filterText} onChange={e => setFilterText(e.target.value)} />
                                </div>
                            )
                        }
                    />
                </Card.Body>
            </Card>
        </Container>

    );
}

const mapDispatchToProps = dispatch => {
    return {
        onGetResellerTransactions: (token) => dispatch(actions.getTransactionsReseller(token))

    }
};

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        transactions: state.transactions.transactions,
        isLoadingTransactions: state.transactions.loading,
        isFailTransactions: state.transactions.error
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ResellerTransactions);