import React, {useState, useEffect} from "react";
import {connect} from 'react-redux';
import Button from 'react-bootstrap/Button'

import Modal from 'react-bootstrap/Modal';
import * as actions from "../store/actions";

function DeleteModal(props) {

    const handleDelete = () => {

    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Delete?
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4></h4>
                <p>
                    Are you sure you want to delete <b>{props.todelete}</b>?
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={handleDelete}>Yes</Button>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default DeleteModal;