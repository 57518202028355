import axios from 'axios';
import * as actionTypes from './actionTypes';
import {
    addClientFail,
    addClientInitialize, addClientLoading, addClientSuccess, getClientFail, getClientInitialize, getClientLoading,
    getClientSuccess, saveClientFail, saveClientInitialize, saveClientLoading, saveClientSuccess,
    setCurrentClient
} from "./clients";

export const dealersLoading = () => {
    return {
        type: actionTypes.DEALERS_LOADING
    }
};

export const getDealerLoading = () => {
    return {
        type: actionTypes.GET_DEALER_LOADING
    }
};

export const saveDealerLoading = () => {
    return {
        type: actionTypes.SAVE_DEALER_LOADING
    }
};

export const addDealerLoading = () => {
    return {
        type: actionTypes.ADD_DEALER_LOADING
    }
};

export const dealersFail = (error) => {
    return {
        type: actionTypes.DEALERS_FAIL,
        error: error
    }
};

export const getDealerFail = (error) => {
    return {
        type: actionTypes.GET_DEALER_FAIL,
        error: error
    }
};

export const saveDealerFail = (error) => {
    return {
        type: actionTypes.SAVE_DEALER_FAIL,
        error: error
    }
};

export const addDealerFail = (error) => {
    return {
        type: actionTypes.ADD_DEALER_FAIL,
        error: error
    }
};

export const dealersSuccess = (data) => {
    return {
        type: actionTypes.DEALERS_SUCCESS,
        payload: data
    }
};

export const setCurrentDealer = (login) => {
    return {
        type: actionTypes.SET_CURRENT_DEALER,
        payload: login
    }
};

export const getDealerInitialize = () => {
    return {
        type: actionTypes.GET_DEALER_INITIALIZE
    }
};

export const addDealerInitialize = () => {
    return {
        type: actionTypes.ADD_DEALER_INITIALIZE
    }
};

export const saveDealerInitialize = () => {
    return {
        type: actionTypes.SAVE_DEALER_INITIALIZE
    }
};

export const getDealerSuccess = (data) => {
    return {
        type: actionTypes.GET_DEALER_SUCCESS,
        payload: data
    }
};

export const addDealerSuccess = (data) => {
    return {
        type: actionTypes.ADD_DEALER_SUCCESS,
        payload: data
    }
};

export const saveDealerSuccess = (data) => {
    return {
        type: actionTypes.SAVE_DEALER_SUCCESS,
        payload: data
    }
};


export const initializeGetDealer = () => {
    return dispatch => {
        dispatch(getDealerInitialize());
    }
};

export const initializeAddDealer = () => {
    return dispatch => {
        dispatch(addDealerInitialize());
    }
};

export const initializeSaveDealer = () => {
    return dispatch => {
        dispatch(saveDealerInitialize());
    }
};

export const setDealer = (login) => {
    return dispatch => {
        console.log('dealer ', login);
        dispatch(setCurrentDealer(login));
    }
};

export const dealerList = (token) => {
    return dispatch => {
        dispatch(dealersLoading());
        axios.get('/reseller/list?token=' + token)
            .then(res => {
                console.log(res);
                dispatch(dealersSuccess(res.data));
            }).catch(error => {
            let message = "Server error, try again later";
            if (error.response){
                message = error.response.data.message;
            }
            dispatch(dealersFail(message));
        });
    }
};

export const getDealer = (token, name) => {
    return dispatch => {
        dispatch(getDealerLoading());
        axios.get('/reseller/dealer/' + name + '?token=' + token )
            .then(res => {
                dispatch(getDealerSuccess(res.data))
            }).catch(error => {
            //console.log('logging error getreseller: ', error.response.data);
            let message = "Server error, try again later";
            if (error.response){
                message = error.response.data.message;
            }

            dispatch(getDealerFail(message));
        })
    }
};

export const addDealer = (token, data) => {
    return dispatch => {
        dispatch(addDealerLoading());
        axios.post('/reseller/dealer', {...data, token: token})
            .then(res => {
                dispatch(addDealerSuccess(res.data))
            }).catch(error => {
            //console.log('logging error: ', error.response.data);
            let message = "Server error, try again later";
            if (error.response){
                message = error.response.data.message;
            }
            dispatch(addDealerFail(message));
        })
    }
};

export const saveDealer = (token, data) => {
    return dispatch => {
        dispatch(saveDealerLoading());
        axios.post('/reseller/save', {...data, token: token})
            .then(res => {
                dispatch(saveDealerSuccess(res.data))
            }).catch(error => {
            //console.log('logging error: ', error.response.data);
            let message = "Server error, try again later";
            if (error.response){
                message = error.response.data.message;
            }
            dispatch(saveDealerFail(message));
        })
    }
};
