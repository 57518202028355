import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import { Button, Form, Alert } from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Redirect } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import * as actions from "../store/actions";
import * as actionTypes from "../store/actions/actionTypes";


function AdminMac(props){

    const [search, setSearch] = useState("00:1A:79");
    const [modalShow, setModalShow] = useState(false);
    const [deleteClient, setDeleteClient] = useState();

    useEffect(() => {
        props.onInitializeDeleteClient();
    },[]);

    useEffect(() => {
        if (props.isDeleteClient.client){
            props.onGetMac(props.token, search);
            var successMessage = 'Deleted ' + props.isDeleteClient.client.name;
            props.onDisplaySnackbar({type: actionTypes.SNACKBAR_TYPE_SUCCESS, message: successMessage});

            setModalShow(false);
            props.onInitializeDeleteClient();
        }
    },[props.isDeleteClient.client]);

    function handleMacSearchSubmit(event) {

        event.preventDefault();
        console.log("search", search);

        const data = {
            search: search

        };
        //console.log(data);
        //props.onAddClient(props.token, data);
        props.onGetMac(props.token, search);
        //props.onGetClientList(props.token, 1, search);
        //searchClientRedirect = <Redirect to="/clients" />
    }
    //00:1A:79:32:23:01
    const handleClose = () => setModalShow(false);

    const setModalClient = (client) => {
        setDeleteClient(client);
        console.log('logging delete client', deleteClient);
        setModalShow(true);
    };

    const handleDeleteClient = () => {
        console.log('deleting...', deleteClient);
        props.onDeleteClientAdmin(props.token, deleteClient);
    };

    let searchMac =
        <Form inline onSubmit={handleMacSearchSubmit}>
            {console.log('logging search: ', search)}

            <Form.Group>
                <MaskedInput
                    mask={['0', '0', ':' , '1', 'A', ':' , '7', '9', ':', /[A-Fa-f0-9]/, /[A-Fa-f0-9]/, ':' , /[A-Fa-f0-9]/, /[A-Fa-f0-9]/, ':' , /[A-Fa-f0-9]/, /[A-Fa-f0-9]/]}
                    className="form-control"
                    placeholder="Mac Address"
                    value={search}
                    guide={true}
                    id="search"
                    onChange={e => setSearch(e.target.value)}
                />
            </Form.Group>
            <Button
                variant="outline-success"
                type="submit"
            >
                Search
            </Button>
        </Form>;

    let macToDelete = null;
    if (props.client){
        macToDelete =
            <Table striped bordered hover size="sm" >
                <thead>
                <tr>
                    <th>Login</th>
                    <th>Mac</th>
                    <th>Expiry</th>
                </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{props.client.name}</td>
                        <td>{props.client.mac}</td>
                        <td>{props.client.expiry}</td>
                        <td className="text-right">
                            <Button variant="outline-danger" onClick={()=> setModalClient(props.client.name)}>Delete</Button>
                        </td>
                    </tr>
                </tbody>
            </Table>
    }

    return (
        <div>

            <h3>Delete Mac</h3>

            <Container>
                {searchMac}
                {macToDelete}
            </Container>
            <Modal
                show={modalShow}
                onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Delete?
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4></h4>
                    <p>
                        Are you sure you want to delete <b>{deleteClient}</b>?
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleDeleteClient}>Yes</Button>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                </Modal.Footer>

            </Modal>
        </div>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        //onInitializeSaveMessage: () => dispatch(actions.initializeSaveMessage()),
        onGetMac: (token, mac) => dispatch(actions.getMac(token, mac)),
        onDeleteClientAdmin: (token, name) => dispatch(actions.deleteClientAdmin(token, name)),
        onInitializeDeleteClient: () => dispatch(actions.initializeDeleteClient()),
        //onSaveMessage: (token, data) => dispatch(actions.saveMessage(token, data)),
        onDisplaySnackbar: (message) => dispatch(actions.showSuccessSnackbar(message))
    }
};

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        client: state.macReducer.client,
        isDeleteClient: state.deleteClientReducer,
        isLoadingSaveMessage: state.saveMessage.loading,
        isErrorSaveMessage: state.saveMessage.error,
        isSuccessSaveMessage: state.saveMessage.message

    }
};

export default connect(mapStateToProps,mapDispatchToProps)(AdminMac);