import axios from 'axios';
import * as actionTypes from './actionTypes';
import {
    addClientFail,
    addClientInitialize, addClientLoading, addClientSuccess, getClientFail, getClientInitialize, getClientLoading,
    getClientSuccess, saveClientFail, saveClientInitialize, saveClientLoading, saveClientSuccess,
    setCurrentClient
} from "./clients";

export const superResellersLoading = () => {
    return {
        type: actionTypes.SUPER_RESELLERS_LOADING
    }
};

export const getSuperResellerLoading = () => {
    return {
        type: actionTypes.GET_SUPER_RESELLER_LOADING
    }
};

export const saveSuperResellerLoading = () => {
    return {
        type: actionTypes.SAVE_SUPER_RESELLER_LOADING
    }
};

export const addSuperResellerLoading = () => {
    return {
        type: actionTypes.ADD_SUPER_RESELLER_LOADING
    }
};

export const superResellersFail = (error) => {
    return {
        type: actionTypes.SUPER_RESELLERS_FAIL,
        error: error
    }
};

export const getSuperResellerFail = (error) => {
    return {
        type: actionTypes.GET_SUPER_RESELLER_FAIL,
        error: error
    }
};

export const saveSuperResellerFail = (error) => {
    return {
        type: actionTypes.SAVE_SUPER_RESELLER_FAIL,
        error: error
    }
};

export const addSuperResellerFail = (error) => {
    return {
        type: actionTypes.ADD_SUPER_RESELLER_FAIL,
        error: error
    }
};

export const superResellersSuccess = (data) => {
    return {
        type: actionTypes.SUPER_RESELLERS_SUCCESS,
        payload: data
    }
};

export const setCurrentSuperReseller = (login) => {
    return {
        type: actionTypes.SET_CURRENT_SUPER_RESELLER,
        payload: login
    }
};

export const getSuperResellerInitialize = () => {
    return {
        type: actionTypes.GET_SUPER_RESELLER_INITIALIZE
    }
};

export const addSuperResellerInitialize = () => {
    return {
        type: actionTypes.ADD_SUPER_RESELLER_INITIALIZE
    }
};

export const saveSuperResellerInitialize = () => {
    return {
        type: actionTypes.SAVE_SUPER_RESELLER_INITIALIZE
    }
};

export const getSuperResellerSuccess = (data) => {
    return {
        type: actionTypes.GET_SUPER_RESELLER_SUCCESS,
        payload: data
    }
};

export const addSuperResellerSuccess = (data) => {
    return {
        type: actionTypes.ADD_SUPER_RESELLER_SUCCESS,
        payload: data
    }
};

export const saveSuperResellerSuccess = (data) => {
    return {
        type: actionTypes.SAVE_SUPER_RESELLER_SUCCESS,
        payload: data
    }
};


export const initializeGetSuperReseller = () => {
    return dispatch => {
        dispatch(getSuperResellerInitialize());
    }
};

export const initializeAddSuperReseller = () => {
    return dispatch => {
        dispatch(addSuperResellerInitialize());
    }
};

export const initializeSaveSuperReseller = () => {
    return dispatch => {
        dispatch(saveSuperResellerInitialize());
    }
};

export const setSuperReseller = (login) => {
    return dispatch => {
        console.log('super reseller ', login);
        dispatch(setCurrentSuperReseller(login));
    }
};

export const superResellerList = (token) => {
    return dispatch => {
        dispatch(superResellersLoading());
        axios.get('/admin/list?token=' + token)
            .then(res => {
                console.log(res);
                dispatch(superResellersSuccess(res.data));
            }).catch(error => {

            dispatch(superResellersFail(error));
        });
    }
};

export const getSuperReseller = (token, name) => {
    return dispatch => {
        dispatch(getSuperResellerLoading());
        axios.get('/admin/superreseller/' + name + '?token=' + token )
            .then(res => {
                dispatch(getSuperResellerSuccess(res.data))
            }).catch(error => {
            //console.log('logging error getreseller: ', error.response.data);
            let message = "Server error, try again later";
            if (error.response){
                message = error.response.data.message;
            }

            dispatch(getSuperResellerFail(message));
        })
    }
};

export const addSuperReseller = (token, data) => {
    return dispatch => {
        dispatch(addSuperResellerLoading());
        axios.post('/admin/superreseller', {...data, token: token})
            .then(res => {
                dispatch(addSuperResellerSuccess(res.data))
            }).catch(error => {
            //console.log('logging error: ', error.response.data);
            let message = "Server error, try again later";
            //console.log(error);
            //console.log('logging error', error.response);
            if (error.response){
                message = error.response.data.message;
            }
            dispatch(addSuperResellerFail(message));
        })
    }
};

export const saveSuperReseller = (token, data) => {
    return dispatch => {
        dispatch(saveSuperResellerLoading());
        axios.post('/admin/save', {...data, token: token})
            .then(res => {
                dispatch(saveSuperResellerSuccess(res.data))
            }).catch(error => {
            //console.log('logging error: ', error.response.data);
            let message = "Server error, try again later";
            if (error.response){
                message = error.response.data.message;
            }
            dispatch(saveSuperResellerFail(message));
        })
    }
};
