import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import { Button, Form, Alert } from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import { Redirect } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner'
import * as actions from "../store/actions";
import * as actionTypes from "../store/actions/actionTypes";
import ResellerClientTransactions from './ResellerClientTransactions';
import DealerPassword from './DealerPassword';

function UpdateDealer(props){

    const [dealer, setDealer] = useState(
        props.currentDealer
    );
    const [transaction, setTransaction] = useState(0);

    const [credits, setCredits] = useState(30);

    const [description, setDescription] = useState();

    const [options, setOptions] = useState([30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000]);

    useEffect(() => {
        //props.onInitializeSaveClient();
        props.onInitializeSaveDealer();
        props.onGetDealer(props.token, props.currentDealerName);

    }, []);

    useEffect(() => {
        setDealer(props.currentDealer);
        console.log('current reseller object', dealer);
    }, [props.currentDealer]);

    useEffect(() => {
        if (props.isErrorSaveDealer){
            var errorMessage = '' + props.isErrorSaveDealer;
            props.onDisplaySnackbar({type: actionTypes.SNACKBAR_TYPE_ERROR, message: errorMessage});
        }
    }, [props.isErrorSaveDealer]);

    const handleAddRemove = (event) => {
        setTransaction(event.target.value);
        if (Number(event.target.value) === 0){
            setCredits(30);
            setOptions([30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000]);
        } else {
            setCredits(1);
            setOptions([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 30, 40, 50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000]);

        }
    };

    const handleChange = (event) => {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        console.log('name', name);
        console.log('value', value);
        const key = target.key;
        setDealer({
            ...dealer,
            [name]: value
        })
    };

    const spinner = (
        <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
        />
    );

//    let options = [...Array(100).keys()].slice(1);
//    options = [...options, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000];
    //let options = [30, 40, 50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000];


    function handleSubmit(event) {
        event.preventDefault();

        const data = {
            name: dealer.name,
            credits: credits,
            desc: dealer.description,
            transaction: transaction

        };
        console.log('logging data', data);
        props.onSaveDealer(props.token, data);

    }

    let saveDealerRedirect = null;
    if(props.isSuccessSaveDealer){
        //console.log('redirecting because', props.isSuccessSaveDealer);
        var successMessage = 'Updated ' + props.isSuccessSaveDealer.name;
        props.onDisplaySnackbar({type: actionTypes.SNACKBAR_TYPE_SUCCESS, message: successMessage});
        props.onGetCredits(props.token);
        saveDealerRedirect = <Redirect to="/dealers" />
    }

    // let displayError = null;
    // if (props.isErrorSaveDealer){
    //     //displayError = <Alert variant="danger">{props.isErrorSaveDealer}</Alert>
    //     var errorMessage = '' + props.isErrorSaveDealer;
    //     props.onDisplaySnackbar({type: actionTypes.SNACKBAR_TYPE_ERROR, message: errorMessage});
    // }

    return (
        <div>
            {saveDealerRedirect}
            <Container>
                <Row>
                    <Col md={3}>
                        <Card>
                            <Card.Header as="h5">Update {props.currentDealer.name}</Card.Header>
                            <Card.Body>
                                <h4></h4>
                                <h5>{props.currentDealer.credits} Credits</h5>
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group controlId="transaction">
                                        <Form.Control
                                            as="select"
                                            onChange={handleAddRemove}
                                        >
                                            <option value={0} >Add</option>
                                            <option value={1} >Remove</option>

                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group controlId="credits">
                                        <Form.Control
                                            as="select"
                                            onChange={e => setCredits(e.target.value)}
                                        >
                                            {options.map(option => {
                                                return <option value={option}>{option}</option>
                                            })}

                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group controlId="description" >
                                        <Form.Control
                                            value={dealer.description}
                                            name="description"
                                            placeholder="Description"
                                            onChange={handleChange}
                                            type="text"
                                        />
                                    </Form.Group>
                                    <Button
                                        block
                                        disabled={props.isLoadingSaveDealer}
                                        type="submit"
                                    >
                                        {props.isLoadingSaveDealer
                                            ? spinner
                                            : 'Submit'}
                                    </Button>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={9}>
                        <Row>
                            <Col md={12} mb={5}>
                                <Card.Header>Change Password</Card.Header>
                                <Card.Body><DealerPassword /></Card.Body>
                            </Col>
                            <Col md={12} mb={5}>
                                <Card.Header>Transactions</Card.Header>
                                <Card.Body><ResellerClientTransactions /></Card.Body>
                            </Col>
                        </Row>
                    </Col>

                </Row>
            </Container>

        </div>
    )

}

const mapDispatchToProps = dispatch => {
    return {
        onGetDealer: (token, name) => dispatch(actions.getDealer(token, name)),
        onInitializeSaveDealer: () => dispatch(actions.initializeSaveDealer()),
        onSaveDealer: (token, data) => dispatch(actions.saveDealer(token, data)),
        onGetCredits: (token) => dispatch(actions.creditsReseller(token)),
        onDisplaySnackbar: (message) => dispatch(actions.showSuccessSnackbar(message))
    }
};

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        currentDealerName: state.dealer.name,
        currentDealer: state.dealer.item,
        isLoadingDealer: state.dealer.loading,
        isErrorSaveDealer: state.saveDealer.error,
        isSuccessSaveDealer: state.saveDealer.dealer,
        isLoadingSaveDealer: state.saveDealer.loading

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateDealer);