import React, { useState, useEffect } from "react";
import { Button, Form, Alert } from "react-bootstrap";
import {connect, useSelector} from 'react-redux';
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import {red, green} from '@material-ui/core/colors';
import InfoIcon from '@material-ui/icons/Info';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import Spinner from 'react-bootstrap/Spinner';
import { makeStyles } from '@material-ui/core/styles';

import * as actions from '../store/actions/index';
import * as actionTypes from '../store/actions/actionTypes';


function DisplaySnackbar(props) {

    function handleClose(){
        props.onClearSnackbar();
    }

    let color = (props.snackbarType === actionTypes.SNACKBAR_TYPE_ERROR)?red[500]:green[500];
    let iconType = (props.snackbarType === actionTypes.SNACKBAR_TYPE_ERROR)?<ErrorIcon style={{marginRight: '5px'}}/>:<CheckCircleIcon style={{marginRight: '5px'}}/>;


    return (

        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
            open={props.isSnackbarOpen}


            autoHideDuration={3000}
            onClose={handleClose}
        >
            <SnackbarContent
                style={{backgroundColor: color, display: 'flex', alignItems: 'center'}}
                aria-describedby="message-id"
                message={
                    <span id="message-id">
                        {iconType}
                        {props.snackbarMessage}
                    </span>
                }
                action={[
                    <IconButton
                        key="close"
                        aria-label="close"
                        color="inherit"
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                ]}
            />
        </Snackbar>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        onClearSnackbar: () => dispatch(actions.clearSnackbar())
    }
};

const mapStateToProps = state => {
    return {
        isSnackbarOpen: state.snackbar.successSnackbarOpen,
        snackbarMessage: state.snackbar.successSnackbarMessage,
        snackbarType: state.snackbar.snackbarType
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DisplaySnackbar);