import React, {useState} from 'react';
import {connect} from 'react-redux';
import { NavLink, Link } from 'react-router-dom';
import { Navbar } from "react-bootstrap";
import { Nav, NavItem} from "react-bootstrap";
import {LinkContainer} from 'react-router-bootstrap';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Redirect } from 'react-router-dom';
import CreditsBadge from './CreditsBadge';
import * as actions from "../store/actions";

function NavItems (props) {

    const [search, setSearch] = useState("");

    let searchClientRedirect = null;
    function handleSubmit(event) {

        event.preventDefault();
        console.log("search", search);

        const data = {
            search: search

        };
        //console.log(data);
        //props.onAddClient(props.token, data);
        props.onSearchClients(search);
        props.onGetClientList(props.token, 1, search);
        searchClientRedirect = <Redirect to="/clients" />
    }

    let navigationLinks = null;
    if (props.isAuthenticated && props.role==='dealer'){
        navigationLinks = (

            <Nav className="mr-auto">
                <LinkContainer to="/"><Nav.Link to="/">Dashboard</Nav.Link></LinkContainer>
                <LinkContainer to="/clients"><Nav.Link to="/clients" eventKey={3}>Clients</Nav.Link></LinkContainer>
                <LinkContainer to="/addclient"><Nav.Link to="/addclient" eventKey={4}>Add Client</Nav.Link></LinkContainer>
                <LinkContainer to="/transactions"><Nav.Link to="/transactions" eventKey={5}>Transactions</Nav.Link></LinkContainer>
            </Nav>
        )
    }

    if (props.isAuthenticated && props.role==='reseller'){
        navigationLinks = (
            <Nav className="mr-auto">
                <LinkContainer to="/"><Nav.Link to="/">Dashboard</Nav.Link></LinkContainer>
                <LinkContainer to="/dealers"><Nav.Link to="/dealers" eventKey={3}>Dealers</Nav.Link></LinkContainer>
                <LinkContainer to="/adddealer"><Nav.Link to="/adddealer" eventKey={4}>Add Dealer</Nav.Link></LinkContainer>
                <LinkContainer to="/transactions"><Nav.Link to="/transactions" eventKey={5}>Transactions</Nav.Link></LinkContainer>

            </Nav>
        )
    }

    if (props.isAuthenticated && props.role==='superreseller'){
        navigationLinks = (
            <Nav className="mr-auto">
                <LinkContainer to="/"><Nav.Link to="/">Dashboard</Nav.Link></LinkContainer>
                <LinkContainer to="/resellers"><Nav.Link to="/resellers" eventKey={3}>Resellers</Nav.Link></LinkContainer>
                <LinkContainer to="/addreseller"><Nav.Link to="/addreseller" eventKey={4}>Add Reseller</Nav.Link></LinkContainer>
                <LinkContainer to="/transactions"><Nav.Link to="/transactions" eventKey={5}>Transactions</Nav.Link></LinkContainer>
            </Nav>
        )
    }


    if (props.isAuthenticated && props.role==='admin'){
        navigationLinks = (
            <Nav className="mr-auto">
                <LinkContainer to="/"><Nav.Link to="/">Dashboard</Nav.Link></LinkContainer>
                <LinkContainer to="/superresellers"><Nav.Link to="/superresellers" eventKey={3}>Super Resellers</Nav.Link></LinkContainer>
                <LinkContainer to="/addsuperreseller"><Nav.Link to="/addsuperreseller" eventKey={4}>Add Super Reseller</Nav.Link></LinkContainer>
                <LinkContainer to="/transactions"><Nav.Link to="/transactions" eventKey={5}>Transactions</Nav.Link></LinkContainer>
                <LinkContainer to="/message"><Nav.Link to="/message" eventKey={6}>Message</Nav.Link></LinkContainer>
                <LinkContainer to="/deletemac"><Nav.Link to="/deletemac" eventKey={7}>Delete Mac</Nav.Link></LinkContainer>
            </Nav>
        )
    }

    return (
        <>
            {navigationLinks}
            <Nav pullRight>
                {!props.isAuthenticated
                    ? <LinkContainer  to="/login"><NavLink to="/login" eventKey={1}>Login</NavLink></LinkContainer>
                    : <LinkContainer to="/logout"><NavLink to="/logout" eventKey={2}>Logout</NavLink></LinkContainer>}
            </Nav>
        </>
    );
}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        name: state.auth.name,
        isAuthenticated: state.auth.token !== null,
        role: state.auth.role
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onGetClientList: (token, page, search) => dispatch(actions.clientList(token, page, search)),
        onSearchClients: (search) => dispatch(actions.searchClients(search))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(NavItems);
