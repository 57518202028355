import * as actionTypes from '../actions/actionTypes';

const initialState = {
    client: false,
    loading: null,
    error: null
};

const reducer = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.RESET_CLIENT_INITIALIZE:
            return {
                ...state,
                loading: null,
                error: null,
                client: false
            };
        case actionTypes.RESET_CLIENT_LOADING:
            return {
                ...state,
                loading: true,
                error: null,
                client: false
            };
        case actionTypes.RESET_CLIENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error,
                client: false
            };
        case actionTypes.RESET_CLIENT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                client: action.payload
            };
        default:
            return state;
    }
};

export default reducer;