import axios from 'axios';
import * as actionTypes from './actionTypes';

export const packagesLoading = () => {
    return {
        type: actionTypes.PACKAGES_LOADING
    }
};

export const packagesFail = (error) => {
    return {
        type: actionTypes.PACKAGES_FAIL,
        error: error
    }
};

export const packagesSuccess = (data) => {
    return {
        type: actionTypes.PACKAGES_SUCCESS,
        payload: data
    }
};

export const packagesList = (token, page) => {
    return dispatch => {
        dispatch(packagesLoading());
        axios.get('/api/packages?token=' + token )
            .then(res => {
                console.log(res);
                dispatch(packagesSuccess(res.data));
            }).catch(error => {
            dispatch(packagesFail(error));
        });
    }
};

export const subPackagesList = (token, name) => {
    return dispatch => {
        dispatch(packagesLoading());
        axios.get('/api/subpackages/' + name + '?token=' + token )
            .then(res => {
                console.log(res);
                dispatch(packagesSuccess(res.data));
            }).catch(error => {
            dispatch(packagesFail(error));
        });
    }
};