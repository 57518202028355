import * as actionTypes from '../actions/actionTypes';

const initialState = {
    token: null,
    userId: null,
    error: null,
    loading: false,
    role: null,
    name: null
};

const reducer = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.AUTH_START:
            return {
                ...state,
                error: null,
                loading: true,
                role: null,
                name: null
            };
        case actionTypes.AUTH_SUCCESS:
            return {
                ...state,
                token: action.payload.token,
                role: action.payload.role,
                name: action.payload.name,
                error: null,
                loading: false
            };
        case actionTypes.AUTH_FAIL:
            return {
                ...state,
                error: action.error,
                name: null,
                loading: false,
                role: null
            };
        case actionTypes.AUTH_LOGOUT:
            return {
                ...state,
                token: null,
                name: null,
                userId: null,
                role: null
            };
        default:
            return state;

    }
};

export default reducer;