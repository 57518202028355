import axios from 'axios';
import * as actionTypes from './actionTypes';
import * as actions from './index';

export const authStart = () => {
    console.log('inside authStart');
    return {
        type: actionTypes.AUTH_START
    }
};

export const authSuccess = (data) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        payload: data
    }
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    }
};

export const authLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationDate');
    localStorage.removeItem('role');
    return {
        type: actionTypes.AUTH_LOGOUT
    }
};

export const checkAuthTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(authLogout());
        }, expirationTime);
    };
};

export const auth = (email, pass, captcha) => {
    console.log('inside auth action');
    return dispatch => {

        dispatch(authStart());
        const authData = {
            login_username: email,
            login_password: pass,
            g_recaptcha_response: captcha

        };
        axios.post('/authenticate', authData)
            .then(response => {
                //console.log(response);
                console.log('logging current time', new Date().getTime());
                const expirationDate = new Date(new Date().getTime() + (3600 * 1000)); // 1 hour
                console.log('expiration data: ', expirationDate);
                //const expirationDate = new Date(new Date().getTime() + response.data.expiresIn * 1000);
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('expirationDate', expirationDate);
                localStorage.setItem('role', response.data.role);
                localStorage.setItem('name', response.data.name);
                console.log('AUTH:::logging response', response);
                dispatch(authSuccess(response.data));
                dispatch(checkAuthTimeout(3600 * 1000));

            })
            .catch(error => {
                let message = "Server error, try again later";
                if (error.response){
                    message = error.response.data.message;
                }
                //console.log('logging error', error);
                dispatch(authFail(message));
            });
    };
};

export const checkAuthState = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        const role = localStorage.getItem('role');
        const name = localStorage.getItem('name');
        console.log('logging token', token);
        console.log('logging role', role);
        //console.log('---logging token', token);
        if (!token || !role){
            console.log('logging role', role);
            dispatch(authLogout());
        } else {
            const expirationDate = new Date(localStorage.getItem('expirationDate'));
            console.log('logging expiration date', expirationDate);
            if (expirationDate <= new Date()){
                dispatch(authLogout());
            } else {
                dispatch(authSuccess({role: role, name: name, token: token}));
                console.log('getTime', expirationDate.getTime());
                console.log('time difference', expirationDate.getTime() - new Date().getTime());
                dispatch(checkAuthTimeout(expirationDate.getTime() - new Date().getTime()));

            }
        }
    };
};
