import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import { Button, Form, Alert } from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Redirect } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner'
import * as actions from "../store/actions";
import * as actionTypes from "../store/actions/actionTypes";


function AddReseller(props){

    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");


    useEffect(() => {
        console.log('inside addreseller');
        props.onInitializeAddReseller();
    }, []);

    //const handleToggle = ({target}) =>
    //    setPackagesList(s => ({ ...s, [target.name]: !s[target.name]}));

    useEffect(() => {
        if (props.isErrorAddReseller){
            var errorMessage = '' + props.isErrorAddReseller;
            props.onDisplaySnackbar({type: actionTypes.SNACKBAR_TYPE_ERROR, message: errorMessage});
        }
    }, [props.isErrorAddReseller]);

    const spinner = (
        <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
        />
    );

    function validateForm() {
        return login.length > 0 && password.length > 0;
    }

    function handleSubmit(event) {
        event.preventDefault();
        console.log("login", login);
        console.log("password", password);

        const data = {
            login: login,
            password: password,

        };
        props.onAddReseller(props.token, data);
    }

    let addResellerRedirect = null;
    if(props.isSuccessAddReseller){
        var successMessage = 'Added ' + props.isSuccessAddReseller.name;
        props.onDisplaySnackbar({type: actionTypes.SNACKBAR_TYPE_SUCCESS, message: successMessage});

        addResellerRedirect = <Redirect to="/resellers" />
    }

    // let displayError = null;
    // if (props.isErrorAddReseller){
    //     //displayError = <Alert variant="danger">{props.isErrorAddReseller}</Alert>
    //     var errorMessage = '' + props.isErrorAddReseller;
    //     props.onDisplaySnackbar({type: actionTypes.SNACKBAR_TYPE_ERROR, message: errorMessage});
    // }


    return (
        <div>
            {addResellerRedirect}
            <h3>Add Reseller</h3>
            <Container>

                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="login" >
                        <Form.Control
                            autoFocus
                            type="text"
                            value={login}
                            placeholder="Enter login name"
                            onChange={e => setLogin(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="password" >
                        <Form.Control
                            value={password}
                            placeholder="Enter password"
                            onChange={e => setPassword(e.target.value)}
                            type="text"
                        />
                    </Form.Group>
                    <Button
                        block
                        disabled={!validateForm() || props.isLoadingAddReseller}
                        type="submit"
                    >
                        {props.isLoadingAddReseller
                            ? spinner
                            : 'Submit'}
                    </Button>
                </Form>
            </Container>
        </div>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        onInitializeAddReseller: () => dispatch(actions.initializeAddReseller()),
        onAddReseller: (token, data) => dispatch(actions.addReseller(token, data)),
        onDisplaySnackbar: (message) => dispatch(actions.showSuccessSnackbar(message))
    }
};

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        isLoadingAddReseller: state.addReseller.loading,
        isErrorAddReseller: state.addReseller.error,
        isSuccessAddReseller: state.addReseller.reseller

    }
};

export default connect(mapStateToProps,mapDispatchToProps)(AddReseller);