import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import {
    BrowserRouter as Router
} from "react-router-dom";

import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import axios from 'axios';
import authReducer from './store/reducers/auth';
import clientsReducer from './store/reducers/clients';
import packagesReducer from './store/reducers/packages';
import creditsReducer from './store/reducers/credits';
import addClientReducer from './store/reducers/addclient';
import clientReducer from './store/reducers/client';
import saveClientReducer from './store/reducers/saveclient';
import deleteClientReducer from './store/reducers/deleteclient';
import resellersReducer from './store/reducers/resellers';
import resellerReducer from './store/reducers/reseller';
import addResellerReducer from './store/reducers/addreseller';
import saveResellerReducer from './store/reducers/savereseller';
import dealersReducer from './store/reducers/dealers';
import dealerReducer from './store/reducers/dealer';
import addDealerReducer from './store/reducers/adddealer';
import saveDealerReducer from './store/reducers/savedealer';
import superResellersReducer from './store/reducers/sresellers';
import superResellerReducer from './store/reducers/sreseller';
import addSuperResellerReducer from './store/reducers/addsreseller';
import saveSuperResellerReducer from './store/reducers/savesreseller';
import getMessageReducer from './store/reducers/message';
import saveMessageReducer from './store/reducers/savemessage';
import snackbarReducer from './store/reducers/snackbar';
import clientInfoReducer from './store/reducers/clientinfo';
import transactionsReducer from './store/reducers/transactions';
import retrieveCreditsReducer from './store/reducers/retrievecredits';
import renewCreditsReducer from './store/reducers/renew';
import searchReducer from './store/reducers/search';
import macReducer from './store/reducers/mac';
import passwordReducer from './store/reducers/password';
import statisticsReducer from './store/reducers/statistics';
import statschartReducer from './store/reducers/statschart';
import totalStatsReducer from './store/reducers/totalstats';
import resetClientReducer from './store/reducers/resetClient';
//import {deleteClient} from "./store/actions";
//import {saveClient} from "./store/actions";

//axios.defaults.baseURL = 'http://jet1.org';
//axios.defaults.baseURL = 'http://localhost:3700';
//axios.defaults.baseURL = 'http://149.56.19.8:3600'; // c
//axios.defaults.baseURL = 'http://158.69.124.7:3700'; // c
axios.defaults.baseURL = 'https://huba1.net';

const rootReducer = combineReducers({
    auth: authReducer,
    clients: clientsReducer,
    packages: packagesReducer,
    credits: creditsReducer,
    addClient: addClientReducer,
    clientReducer: clientReducer,
    saveClient: saveClientReducer,
    resetClient: resetClientReducer,
    deleteClientReducer: deleteClientReducer,
    resellers: resellersReducer,
    reseller: resellerReducer,
    addReseller: addResellerReducer,
    saveReseller: saveResellerReducer,
    dealers: dealersReducer,
    dealer: dealerReducer,
    addDealer: addDealerReducer,
    saveDealer: saveDealerReducer,
    superResellers: superResellersReducer,
    superReseller: superResellerReducer,
    addSuperReseller: addSuperResellerReducer,
    saveSuperReseller: saveSuperResellerReducer,
    getMessage: getMessageReducer,
    saveMessage: saveMessageReducer,
    snackbar: snackbarReducer,
    clientInfo: clientInfoReducer,
    transactions: transactionsReducer,
    retrieveCredits: retrieveCreditsReducer,
    renewCredits: renewCreditsReducer,
    searchClients: searchReducer,
    macReducer: macReducer,
    passwordReducer: passwordReducer,
    statistics: statisticsReducer,
    statschart: statschartReducer,
    totalstats: totalStatsReducer
});

const logger = state => {
    return next => {
        return action => {
            const result = next(action);
            return result;

        }
    }
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(logger, thunk)));


ReactDOM.render(
    <Provider store={store}>
        <Router>
            <App />
        </Router>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
