import axios from 'axios';
import * as actionTypes from './actionTypes';
import {deleteClientFail, deleteClientLoading, deleteClientSuccess, saveClientInitialize} from "./clients";

export const getMacLoading = () => {
    return {
        type: actionTypes.GET_MAC_LOADING
    }
};

export const getMacFail = (error) => {
    return {
        type: actionTypes.GET_MAC_FAIL,
        error: error
    }
};

export const getMacSuccess = (data) => {
    return {
        type: actionTypes.GET_MAC_SUCCESS,
        payload: data
    }
};

const deleteClientInitialize = () => {
    return {
        type: actionTypes.DELETE_CLIENT_INITIALIZE
    }
};


export const deleteClientAdminLoading = () => {
    return {
        type: actionTypes.DELETE_CLIENT_LOADING
    }
};

export const deleteClientAdminFail = (error) => {
    return {
        type: actionTypes.DELETE_CLIENT_FAIL,
        error: error
    }
};

export const deleteClientAdminSuccess = (data) => {
    return {
        type: actionTypes.DELETE_CLIENT_SUCCESS,
        payload: data
    }
};

// export const initializeDeleteClient = () => {
//     return dispatch => {
//         dispatch(deleteClientInitialize());
//     }
// };

export const getMac = (token, mac) => {
    return dispatch => {
        dispatch(getMacLoading());
        axios.get('/admin/searchmac/' + mac + '?token=' + token )
            .then(res => {
                console.log(res);
                dispatch(getMacSuccess(res.data));
            }).catch(error => {
            let message = "Server error, try again later";
            if (error.response){
                message = error.response.data.message;
            }
            console.log('logging message', message);
            dispatch(getMacFail(message));
        });
    }
};

export const deleteClientAdmin = (token, name) => {
    return dispatch => {
        dispatch(deleteClientLoading());
        axios.post('/admin/remove', {token: token, name: name})
            .then(res => {
                dispatch(deleteClientSuccess(res.data))
            }).catch(error => {
            //console.log('logging error: ', error.response.data);
            let message = "Server error, try again later";
            if (error.response){
                message = error.response.data.message;
            }
            dispatch(deleteClientFail(message));
        })
    }
};

/*
export const initializeSaveMessage = () => {
    return dispatch => {
        dispatch(initializeMessage());
    }
};

export const saveMessage = (token, data) => {
    return dispatch => {
        dispatch(saveMessageLoading());
        axios.post('/admin/message', {...data, token: token})
            .then(res => {
                console.log(res);
                dispatch(saveMessageSuccess(res.data));
            }).catch(error => {
            let message = "Server error, try again later";
            if (error.response){
                message = error.response.data.message;
            }
            dispatch(saveMessageFail(message));
        });
    }
};*/
